import React, {useContext, useState} from 'react';
import {Form, Input, Button, Modal, Spin, Checkbox} from 'antd';
import {useNavigate} from 'react-router-dom';
import emailjs from 'emailjs-com';
import "./Signup.css";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import Footer from "../../Footer/Footer";
import Contact from "../../Contact/Contact";
import HeaderStore from "../../Header/HeaderStore";
import {ThemeContext} from "../../../contexts/theme";
import Header from "../../Header/Header";

const Signup = () => {

    const [{themeName}] = useContext(ThemeContext);

    const [emailStatus, setEmailStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [isModalVisibleFinish, setIsModalVisibleFinish] = useState(false); // Corrected initial state
    const [confirmEnabled, setConfirmEnabled] = useState(false);

    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
        setTimeout(() => {
            setIsModalVisible(false);
        }, 3000);
    };

    const showModalFinish = () => {
        setTimeout(() => {
            setIsModalVisibleFinish(true);
            setTimeout(() => {
                setIsModalVisibleFinish(false);
                navigate('/');
            }, 3000); // Delay navigation after showing the "ثبت‌نام موفق" modal
        }, 0);
    };

    const handleConfirm = () => {
        setIsModalVisible(false);
    };

    const handleConfirmFinish = () => {
        setIsModalVisibleFinish(false);
    };

    const handleCancel = () => {
        navigate('/');
    };

    const onFinish = (values) => {
        setIsLoading(true);
        console.log('Success:', values);
        const serviceID = 'service_8p5qn05';
        const templateID = 'template_qw3x3fr';
        const userID = 'PW3bLNjhb80qty82v';

        emailjs.send(serviceID, templateID, {
            user_name: values.username,
            user_lastname: values.lastname,
            user_nationalcode: values.nationalcode,
            user_phonenumber: values.phonenumber,
            user_email: values.email,
            user_description: values.description,
            to_email: values.email,
            subject: 'ثبت‌نام شما با موفقیت انجام شد'
        }, userID).then((response) => {
            console.log('Email sent successfully to user:', response.status, response.text);
            setEmailStatus('success');
            setIsLoading(false);
            setIsFormVisible(false);
            showModalFinish();
        }, (error) => {
            console.error('Failed to send email to user:', error);
            setEmailStatus('error');
            setIsLoading(false);
        });

        emailjs.send(serviceID, templateID, {
            user_name: values.username,
            user_lastname: values.lastname,
            user_nationalcode: values.nationalcode,
            user_phonenumber: values.phonenumber,
            user_email: values.email,
            user_description: values.description,
            to_email: 'javataredu@gmail.com',
            subject: 'اطلاع‌رسانی ثبت‌نام کاربر جدید'
        }, userID).then((response) => {
            console.log('Email sent successfully to admin:', response.status, response.text);
        }, (error) => {
            console.error('Failed to send email to admin:', error);
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setIsLoading(false);
    };

    const handleCheckboxChange = (e) => {
        setConfirmEnabled(e.target.checked);
    };

    return (
        <div id='top' className={`${themeName} app`}>
            {/* <HeaderStore/> */}
            <Header/>
            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>

                {isFormVisible && (
                    <div className='signup-container center'>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '15px',
                            backgroundColor: 'transparent'
                        }}>
                            <img src="https://imagizer.imageshack.com/img923/8484/Q6PHeP.png" alt="لوگو"
                                 style={{maxWidth: '150px', height: 'auto', backgroundColor: 'transparent'}}/>
                        </div>
                        <h2 className="sogandUser">فرم ثبت‌نام</h2>
                        <Form name='signup'
                              initialValues={{remember: true}}
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}>
                            <Form.Item name='username'
                                       rules={[{required: true, message: 'لطفاً نام کاربری خود را وارد کنید!'}]}>
                                <Input placeholder='نام'/>
                            </Form.Item>
                            <Form.Item name='lastname'>
                                <Input placeholder='نام خانوادگی'/>
                            </Form.Item>
                            <Form.Item name='nationalcode'>
                                <Input placeholder='کد ملی' className='nationalcode-input'/>
                            </Form.Item>
                            <Form.Item name='phonenumber'>
                                <Input placeholder='شماره تلفن' className='phone-input'/>
                            </Form.Item>
                            <Form.Item name='email' rules={[{
                                required: true,
                                message: 'لطفاً ایمیل خود را وارد کنید!'
                            }, {type: 'email', message: 'ایمیل وارد شده معتبر نیست!'}]}>
                                <Input placeholder='ایمیل' className='email-input'/>
                            </Form.Item>
                            <Form.Item name='description'>
                                <Input.TextArea placeholder='توضیحات  /  درخواست' className='description-input'
                                                autoSize={{minRows: 4, maxRows: 6}}/>
                            </Form.Item>
                            <Form.Item className='signup-btn-container'>
                                <Button htmlType='submit' type='button' className='btn btn--outline vazirlightd'
                                        disabled={!confirmEnabled}>ثبت‌نام</Button>
                            </Form.Item>
                        </Form>
                    </div>
                )}

                {isLoading && (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Spin size="large"/>
                    </div>
                )}

                <Contact/>
            </main>

            <ScrollToTop/>
            <Footer/>
            <Modal
                title={<span style={{fontFamily: 'Lalezar', textAlign: 'center', display: 'block'}}>نکات قبل از ثبت نام در کوچینگ</span>}
                visible={isModalVisible}
                onOk={handleConfirm}
                onCancel={handleCancel}
                okText="شروع ثبت نام"
                cancelText="بازگشت به صفحه اصلی"
                centered
                okButtonProps={{disabled: !confirmEnabled}}
                bodyStyle={{direction: 'rtl', fontFamily: 'Vazir', textAlign: 'center'}}
                footer={[
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <Button key="back" onClick={handleCancel} style={{fontFamily: 'Vazir', marginLeft: '10px'}}>
                            بازگشت به صفحه اصلی
                        </Button>
                        <Button key="submit" type="primary" onClick={handleConfirm} disabled={!confirmEnabled}
                                style={{fontFamily: 'Vazir'}}>
                            شروع ثبت نام
                        </Button>
                    </div>
                ]}>
                <ul style={{paddingRight: '20px', listStyleType: 'disc', textAlign: 'right'}}>
                    <li style={{marginBottom: '10px'}}>مسیر کوچینگ را در <a href="/training-path">این آدرس</a> مطالعه
                        کنید.
                    </li>
                    <li style={{marginBottom: '10px'}}>اگر برنامه‌نویسی را تازه شروع می‌کنید، از <a
                        href="/question">اینجا</a> استعدادیابی کنید.
                    </li>
                    <li style={{marginBottom: '10px'}}>نتیجه استعدادیابی و درخواست کوچینگ خود را در توضیحات پیام ارسال
                        کنید.
                    </li>
                    <li style={{marginBottom: '10px'}}>تعداد پذیرش کوچینگ هر ماه محدود می‌باشد و در صورت عدم تماس از
                        پشتیبانی مجدد پیگیری کنید.
                    </li>
                    <li style={{marginBottom: '10px'}}>در صورت نیاز به کوچینگ، هزینه مشاوره بر عهده متقاضی می‌باشد.</li>
                    {/* <li style={{ marginBottom: '10px' }}>هزینه کامل کوچ معادل یک الی دو ماه حقوق کاری در کشور درخواستی است.</li> */}
                </ul>
                <Checkbox onChange={handleCheckboxChange} style={{marginTop: '10px', fontSize: '12px'}}>
                    تمامی موارد با دقت مطالعه شده و مورد تایید می‌باشد
                </Checkbox>
            </Modal>


            <Modal className="bNazanin" style={{textAlign: 'center'}} title="ثبت‌نام موفق"
                   visible={isModalVisibleFinish}
                   onOk={handleConfirmFinish}
                   footer={null}
                   closable={false}
                   centered>
                <div>
                    <img src="https://imagizer.imageshack.com/img923/8484/Q6PHeP.png" alt="لوگو" style={{
                        maxWidth: '100px',
                        height: 'auto',
                        marginBottom: '20px',
                        backgroundColor: 'transparent'
                    }}/>
                    <p className="bNazanint" style={{textAlign: 'center'}}>ثبت‌نام شما با موفقیت انجام شد و ایمیل تایید
                        ارسال شد</p>
                    <p className="bNazanint" style={{textAlign: 'center'}}>همکاران ما در بخش پشتیبانی در اسرع وقت با شما
                        تماس خواهند گرفت</p>
                    {/* <p className="bNazanint" style={{textAlign: 'center'}}>دکتر اسماعیل صادقی</p> */}
                </div>
            </Modal>
        </div>
    );
};

export default Signup;
