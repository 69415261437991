import React, {useContext, useState} from "react";
import {
    HeartOutlined, HeartFilled, ShareAltOutlined, LinkOutlined, ShoppingCartOutlined
} from '@ant-design/icons';
import {notification, ConfigProvider, Image, Input} from 'antd';
import {ThemeContext} from "../../../contexts/theme";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import Footer from "../../Footer/Footer";
import './Shop.css';
import Header from "../../Header/Header";

const products = [
    {
        id: 1,
        title: "برنامه نویسی جاوا SE",
        price: "۱,۴۰۰,۰۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "این دوره آموزشی جامع شامل آشنایی با اصول و مبانی برنامه‌نویسی جاوا SE است. از مفاهیم پایه‌ای زبان جاوا گرفته تا برنامه‌نویسی شیءگرا، مدیریت استثناها، دستکاری رشته‌ها و ساختارهای داده‌ای مهم همگی پوشش داده شده‌اند. با این دوره، مهارت‌های لازم برای توسعه‌ی برنامه‌های کاربردی جاوا را کسب خواهید کرد",
        duration: "۶۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/img924/2365/Mw7vHC.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/file/d/14vr6G6PuZi-a6XUNDelJX1HcPkmgR0mO/view?usp=drive_link"
    }, {
        id: 2,
        title: "برنامه نویسی جاوا EE",
        price: "۱,۵۰۰,۰۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "دوره برنامه‌نویسی جاوا EE (Jakarta EE) یکی از محبوب‌ترین دوره‌های آموزشی در زمینه برنامه‌نویسی سطح بالا است. این دوره به شما امکان می‌دهد تا با مفاهیم پیشرفته و کاربردی جاوا EE آشنا شوید و مهارت‌های لازم برای توسعه برنامه‌های تحت وب مقیاس‌پذیر و کارآمد را در اختیارتان قرار دهد",
        duration: "۴۵ ساعت",
        prerequisites: "دانش در سطح دوره Java SE: آشنایی با ساختار برنامه‌های جاوا و مفاهیم اولیه وب و دیتابیس",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/img924/1205/KsNqH4.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/file/d/1Fcrnf0BxO9DfKApNeu6amaV-iYLQjiGv/view?usp=drive_link"
    }, {
        id: 3,
        title: "اسپرینگ فریمورک",
        price: "۱,۹۰۰,۰۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "اسپرینگ یکی از محبوب‌ترین فریمورک‌های جاوا برای توسعه اپلیکیشن‌های سازمانی است. این دوره آموزشی به شما نحوه‌ی استفاده از قابلیت‌های اسپرینگ، شامل مدیریت وابستگی‌ها، AOP، اسپرینگ MVC و امنیت اسپرینگ را آموزش می‌دهد. با گذراندن این دوره، می‌توانید اپلیکیشن‌های مقیاس‌پذیر و پایدار را با استفاده از اسپرینگ توسعه دهید",
        duration: "۴۰ ساعت",
        prerequisites: "دانش در سطح دوره Java SE: آشنایی با ساختار برنامه‌های جاوا و مفاهیم اولیه وب و دیتابیس",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/img924/9468/1YXwHk.jpg",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 4,
        title: "اسپرینگ بوت",
        price: "۱,۸۰۰,۰۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "اسپرینگ بوت به شما امکان توسعه سریع و ساده‌ی اپلیکیشن‌های جاوا را می‌دهد. این دوره آموزشی به شما نحوه‌ی پیکربندی و ایجاد پروژه‌های اسپرینگ بوت، استفاده از دیتابیس‌ها و امنیت را آموزش می‌دهد. با گذراندن این دوره، می‌توانید اپلیکیشن‌های تحت وب خود را به سرعت و به آسانی توسعه و مستقر کنید",
        duration: "۳۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/v2/641x641q50/924/KAeBoR.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/file/d/10cznZGcxTwur7Y0EVfOEpG0m-Sqld_7c/view?usp=drive_link"
    }, {
        id: 5,
        title: "معماری میکروسرویس",
        price: "۱,۹۰۰,۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "معماری میکروسرویس روشی مدرن برای توسعه اپلیکیشن‌های مقیاس‌پذیر و انعطاف‌پذیر است. این دوره آموزشی شما را با اصول معماری میکروسرویس، طراحی و پیاده‌سازی میکروسرویس‌ها، مدیریت ارتباطات بین سرویس‌ها و ابزارهای مرتبط نظیر Docker و Kubernetes آشنا می‌کند. با این دوره، توانایی طراحی و پیاده‌سازی معماری میکروسرویس برای اپلیکیشن‌های پیچیده را خواهید داشت",
        duration: "۴۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/img922/2258/f5JHa1.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/drive/folders/1vh1o_94OMyY6Ohi-JDp1610De5Q8OhtK"
    }, {
        id: 6,
        title: "مانگودیبی",
        price: "۱,۹۰۰,۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "این دوره برای توسعه‌دهندگان و علاقه‌مندان به پایگاه‌داده‌ها طراحی شده است که می‌خواهند به یک سطح پیشرفته در کار با MongoDB برسند. این دوره شامل مباحث تئوری و عملی است که شما را با ویژگی‌های قدرتمند این پایگاه‌داده NoSQL آشنا می‌کند",
        duration: "۳۵ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/v2/640x640q70/923/LR1VIY.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/file/d/1XDvK_YB2s_9TJ70RkPvINvHvIksmN81p/view?usp=drive_link"
    }, {
        id: 7,
        title: "کوچینگ برنامه نویسی",
        price: "۲,۵۰۰,۰۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: " ساعات اولیه بسته به نیاز شما قابل تغییر است. این ساعات شامل فرآیندهای استعدادیابی، مشاوره‌های تخصصی، آموزش‌های جامع، ارتقاء مهارت‌ها، ارزیابی‌های روان‌شناختی، پروژه‌های کاربردی و آماده‌سازی کامل برای مصاحبه‌ها می‌باشد. فرآیندهای کاریابی و مهاجرت در صورت درخواست متقاضی به صورت جداگانه محاسبه می‌شوند. ",
        description: " کوچینگ برنامه‌نویسی ما برای تمامی سطوح برنامه‌نویسان طراحی شده است، از مبتدی تا حرفه‌ای. این دوره شامل جلسات فردی و گروهی با مربیان با تجربه است که شما را در هر مرحله از مسیر یادگیری‌تان همراهی می‌کنند",
        duration: "۱۵۰ ساعت ",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/v2/654x655q70/923/DLhyk9.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 8,
        title: "دیواپس",
        price: "۱,۹۰۰,۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "دوره دیواپس ما برای تمامی سطوح از مبتدی تا حرفه‌ای طراحی شده است. این دوره به شما مهارت‌های لازم برای ترکیب توسعه نرم‌افزار (Development) و عملیات فناوری اطلاعات (Operations) را آموزش می‌دهد. شامل مفاهیمی مانند اتوماسیون فرآیندها، مدیریت پیکربندی، استقرار پیوسته (CI/CD)، نظارت و لاگینگ، و امنیت در دیواپس. از ابزارهایی مانند Jenkins، Docker، Kubernetes، Ansible و بسیاری دیگر استفاده خواهید کرد. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا به عنوان یک دیواپس مهندس حرفه‌ای وارد بازار کار شوید.",
        duration: "۴۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی و مدیریت سیستم‌ها",
        audience: "برنامه‌نویسان و مدیران سیستم‌ها",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/v2/655x656q70/924/0s8PoL.png",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/drive/folders/1LO86N5jjUgW9247IHy69j2kP-GhSCzmF"
    }, {
        id: 9,
        title: "داکر",
        price: "۱,۹۰۰,۰۰۰ تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "دوره جامع داکر ما برای تمامی سطوح از مبتدی تا حرفه‌ای طراحی شده است. این دوره به شما مهارت‌های لازم برای کار با داکر را آموزش می‌دهد. شامل مفاهیمی مانند ایجاد و مدیریت کانتینرها، کار با داکرفایل‌ها و ساخت ایمیج‌ها، استفاده از داکر در محیط‌های توسعه و تولید و امنیت در داکر. از ابزارهایی مانند Docker، Kubernetes، Jenkins و بسیاری دیگر استفاده خواهید کرد. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا به عنوان یک متخصص داکر وارد بازار کار شوید.",
        duration: "۲۰ - ۵۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی و مدیریت سیستم‌ها",
        audience: "برنامه‌نویسان، مدیران سیستم‌ها و مهندسان DevOps",
        delivery: "آنلاین / خصوصی",
        image: "https://imagizer.imageshack.com/v2/641x641q50/922/TPKVGi.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 10,
        title: "پایتون",
        price: "۱,۳۰۰,۰۰۰ تومان",
        teacher: "دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد.",
        description: "این دوره جامع شما را با اصول و مبانی برنامه‌نویسی پایتون آشنا می‌کند و تا سطوح پیشرفته ادامه می‌دهد. شامل مباحثی مانند متغیرها، ساختارهای داده، توابع، شی‌گرایی، ماژول‌ها و کتابخانه‌های مهم پایتون می‌شود. همچنین به موضوعاتی مانند علم داده، یادگیری ماشین و توسعه وب با استفاده از پایتون پرداخته خواهد شد. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا بتوانید پروژه‌های واقعی را با پایتون انجام دهید.",
        duration: "۲۰ - ۵۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی تا پیشرفته، علاقه‌مندان به علم داده و یادگیری ماشین",
        delivery: "آنلاین / خصوصی",
        image: "https://imagizer.imageshack.com/v2/642x641q50/922/soJrn3.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 11,
        title: "گیت ",
        price: "۱,۵۰۰,۰۰۰ تومان",
        teacher: "دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد.",
        description: "این دوره جامع شما را با اصول و مبانی کار با گیت و مدیریت نسخه‌ها آشنا می‌کند و تا سطوح پیشرفته ادامه می‌دهد. شامل مباحثی مانند ایجاد و مدیریت مخازن، استفاده از دستورات گیت، شاخه‌بندی، مدیریت تغییرات، حل تعارض‌ها، و استفاده از ابزارهای مرتبط با گیت مانند GitHub و GitLab می‌شود. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا به عنوان یک توسعه‌دهنده با تجربه در کار با گیت وارد بازار کار شوید.",
        duration: "۲۰ - ۵۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان، توسعه‌دهندگان نرم‌افزار، و مدیران پروژه",
        delivery: "آنلاین / خصوصی",
        image: "https://imagizer.imageshack.com/v2/641x641q50/923/1R8cEC.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 12,
        title: "ری اکت",
        price: "۱,۹۰۰,۰۰۰ تومان",
        teacher: "دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد.",
        description: "این دوره جامع شما را با اصول و مبانی توسعه وب با استفاده از React JS آشنا می‌کند و تا سطوح پیشرفته ادامه می‌دهد. شامل مباحثی مانند ایجاد کامپوننت‌ها، مدیریت حالت، روتینگ، ارتباط با API‌ها، و استفاده از کتابخانه‌هایی مانند Redux و React Router می‌شود. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا به عنوان یک توسعه‌دهنده حرفه‌ای React JS وارد بازار کار شوید.",
        duration: "۲۰ - ۵۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای HTML، CSS و JavaScript",
        audience: "برنامه‌نویسان، توسعه‌دهندگان وب، و علاقه‌مندان به فریم‌ورک‌های جاوااسکریپت",
        delivery: "آنلاین / خصوصی",
        image: "https://imagizer.imageshack.com/v2/640x641q50/922/rRXxsJ.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }
    // {
    //     id: ,
    //     title: "",
    //     price: "۱,۹۰۰,۰۰۰ تومان",
    //     teacher: "  دکتر اسماعیل صادقی",
    //     info: " ",
    //     description: " ",
    //     duration: "۲۰ - ۵۰ ساعت",
    //     prerequisites: " ",
    //     audience: " ",
    //     delivery: "آنلاین / خصوصی",
    //     image: " ",
    //     likes: 0,
    //     comments: [],
    //     driveLink: "#"
    // }
];

// eslint-disable-next-line no-shadow
const searchProducts = (searchTerm, products) => {
    if (!searchTerm) {
        return products; // If search term is empty, return all products
    }
    return products.filter(product =>
        Object.values(product).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
};

const Shop = () => {
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
        }).catch((err) => {
        });
    };

    const [{themeName}] = useContext(ThemeContext);
    const [productList, setProductList] = useState(products);
    const [likedProducts, setLikedProducts] = useState({});
    const [expandedProductId, setExpandedProductId] = useState(null);
    const [notifyCount, setNotifyCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredProducts, setFilteredProducts] = useState(products);
    const {Search} = Input;

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        const results = searchProducts(term, products);
        setFilteredProducts(results);
    };

    const handleLikeToggle = (productId) => {
        const isLiked = likedProducts[productId];
        const updatedProducts = productList.map(product => {
            if (product.id === productId) {
                return {...product, likes: isLiked ? product.likes - 1 : product.likes + 1};
            }
            return product;
        });
        setProductList(updatedProducts);
        setLikedProducts({...likedProducts, [productId]: !isLiked});
    };

    const handleCommentLike = (productId, commentId) => {
        const updatedProducts = productList.map(product => {
            if (product.id === productId) {
                const updatedComments = product.comments.map(comment => {
                    if (comment.id === commentId) {
                        return {...comment, likes: comment.likes + 1};
                    }
                    return comment;
                });
                return {...product, comments: updatedComments};
            }
            return product;
        });
        setProductList(updatedProducts);
    };

    const handleReply = (productId, commentId, replyText) => {
        const updatedProducts = productList.map(product => {
            if (product.id === productId) {
                const updatedComments = product.comments.map(comment => {
                    if (comment.id === commentId) {
                        const newReply = {id: Date.now(), text: replyText, likes: 0};
                        return {...comment, replies: [...comment.replies, newReply]};
                    }
                    return comment;
                });
                return {...product, comments: updatedComments};
            }
            return product;
        });
        setProductList(updatedProducts);
    };

    const toggleMore = (productId) => {
        setExpandedProductId(expandedProductId === productId ? null : productId);
    };

    const handleShoppingCartClick = () => {
        notification.error({
            message: 'خطا', description: 'در حال حاضر ارتباط با درگاه پرداخت مقدور نمی باشد.', duration: 3, // مدت زمان نمایش نوتیفیکیشن (3 ثانیه)
            placement: 'topLeft', // موقعیت نمایش نوتیفیکیشن
            className: 'notification-rtl' // کلاس برای راست چین کردن نوتیفیکیشن و تنظیم فونت
        });
        setNotifyCount(notifyCount + 1);
    };

    return (
        <ConfigProvider direction="rtl">
            <div id='top' className={`${themeName} app`}>
                <Header/>
                <main>
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder=" جستجو   "
                            value={searchTerm}
                            onChange={handleSearch}
                            className="search-input"
                        />
                    </div>
                    <div className='product-list'>
                        {filteredProducts.map(product => (
                            <div key={product.id} className='product-card'>
                                <img src={product.image} alt={product.title} className='product-image'/>
                                <h2 className="lalezarShop">
                                    {product.title}
                                    <span className="price"><span
                                        style={{fontSize: 'smaller', opacity: 0.5, marginLeft: '5px'}}> (خصوصی)
                                    </span>{product.price}</span>
                                </h2>
                                {product.description && (
                                    <>
                                        <p className={`bNazanintdrD ${expandedProductId === product.id ? '' : 'product-description'}`}
                                           style={{marginBottom: '20px'}}>
                                            {expandedProductId === product.id && product.description}
                                        </p>
                                        {expandedProductId === product.id && (
                                            <>
                                                <p style={{marginBottom: '3px'}}>
                                                    <span className="bNazanintdrDB">مدرس دوره:</span>
                                                    <span className="bNazanintdrD">{product.teacher}</span>
                                                </p>
                                                <p style={{marginBottom: '3px'}}>
                                                    <span className="bNazanintdrDB">مدت زمان:</span>
                                                    <span className="bNazanintdrD">{product.duration}</span>
                                                </p>
                                                {product.prerequisites && (
                                                    <p style={{marginBottom: '3px'}}>
                                                        <span className="bNazanintdrDB">پیش‌نیاز:</span>
                                                        <span className="bNazanintdrD">{product.prerequisites}</span>
                                                    </p>
                                                )}
                                                {product.audience && (
                                                    <p style={{marginBottom: '3px'}}>
                                                        <span className="bNazanintdrDB">مخاطبان دوره:</span>
                                                        <span className="bNazanintdrD">{product.audience}</span>
                                                    </p>
                                                )}
                                                {product.delivery && (
                                                    <p style={{marginBottom: '3px'}}>
                                                        <span className="bNazanintdrDB">نحوه برگزاری:</span>
                                                        <span className="bNazanintdrD"
                                                              dangerouslySetInnerHTML={{__html: product.delivery}}/>
                                                    </p>
                                                )}
                                                {product.info && (
                                                    <p style={{marginBottom: '3px', textAlign: 'justify'}}>
                                                        <span className="bNazanintdrDB">توضیحات:</span>
                                                        <span className="bNazanintdrD">{product.info}</span>
                                                    </p>
                                                )}
                                            </>
                                        )}
                                        {expandedProductId !== product.id && (
                                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                                            <span className="more-button" onClick={() => toggleMore(product.id)}> ... more </span>
                                        )}
                                    </>
                                )}
                                <div className='product-actions'>
                                    {likedProducts[product.id] ? (
                                        <HeartFilled style={{color: 'red'}}
                                                     onClick={() => handleLikeToggle(product.id)}/>
                                    ) : (
                                        <HeartOutlined onClick={() => handleLikeToggle(product.id)}/>
                                    )} {product.likes}
                                    <ShareAltOutlined style={{marginRight: '10px'}}
                                                      onClick={() => copyToClipboard(`${window.location.href}product/${product.id}`)}/>
                                    <a href={product.driveLink} target="_blank" rel="noopener noreferrer">
                                        <LinkOutlined style={{marginRight: '10px'}}/>
                                    </a>
                                    <ShoppingCartOutlined
                                        style={{marginRight: '10px'}}
                                        onClick={handleShoppingCartClick}
                                    />
                                </div>
                                <div className='product-comments'>
                                    {product.comments.map(comment => (
                                        <div key={comment.id}
                                             className='product-comment'>
                                            <p>{comment.text}</p>
                                            <div className='comment-actions'>
                                                <HeartOutlined
                                                    onClick={() => handleCommentLike(product.id, comment.id)}
                                                /> {comment.likes}
                                                {/* eslint-disable-next-line react/button-has-type */}
                                                <button onClick={() => handleReply(product.id, comment.id, "پاسخ")}>
                                                    Reply
                                                </button>
                                            </div>
                                            {comment.replies.map(reply => (
                                                <div key={reply.id} className='comment-reply'>
                                                    <p>{reply.text}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </main>
                <ScrollToTop/>
                <Footer/>
            </div>
        </ConfigProvider>
    );
};

export default Shop;
