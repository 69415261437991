import {faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuOutlined, CloseOutlined} from '@ant-design/icons';
import {useContext, useState} from 'react'
import {ThemeContext} from '../../contexts/theme'
import {projects, skills, contact} from '../../portfolio'
import './Navbar.css'

const Navbar = () => {
    const [{themeName, toggleTheme}] = useContext(ThemeContext)
    const [showNavList, setShowNavList] = useState(false)

    const toggleNavList = () => setShowNavList(!showNavList)

    const d = new Date();
    const options = { weekday: 'long',  month: 'long', day: 'numeric' };
    const option = { year: 'numeric'};

    return (
        <nav className='center nav'>
            <ul
                style={{display: showNavList ? 'flex' : null}}
                className='nav__list'
            >

                {contact.email ? (
                    <li className='nav__list-item lalezar'>
                        <a
                            href='/#contact'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            {/* Contact */}
                            تماس با ما
                        </a>
                    </li>
                ) : null}

                {skills.length ? (
                    <li className='nav__list-item lalezar'>
                        <a
                            href='/#skills'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            {/* Skills */}
                            مهارت ها
                        </a>
                    </li>
                ) : null}

                {projects.length ? (
                    <li className='nav__list-item'>
                        <a
                            href='/#projects'
                            onClick={toggleNavList}
                            className='link link--nav lalezar'
                        >
                            {/* Courses */}
                            آموزش ها
                        </a>
                    </li>
                ) : null}
            </ul>

            <button
                type='button'
                onClick={toggleTheme}
                className='btn btn--icon nav__theme'
                aria-label='toggle theme'
            >
                {/* {themeName === 'dark' ? <EyeOutlined   style={{fontSize: '180%'}}/> : <StarOutlined style={{fontSize: '180%'}}/>} */}
                {themeName === 'dark' ? <FontAwesomeIcon icon={faSun} style={{fontSize: '150%'}}/> : <FontAwesomeIcon icon={faMoon} style={{fontSize: '150%'}}/>}
            </button>

            <button
                type='button'
                onClick={toggleNavList}
                className='btn btn--icon nav__hamburger'
                aria-label='toggle navigation'
            >
                {showNavList ? <CloseOutlined/> : <MenuOutlined/>}
            </button>
        </nav>
    )
}

export default Navbar
