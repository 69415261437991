// eslint-disable-next-line import/order
import {contact} from '../../portfolio';
import './Contact.css';
import React, {useState} from 'react';
import {Form, Input, Button, notification} from 'antd';
import {
    PhoneOutlined,
    EnvironmentOutlined,
    MailOutlined,
    PhoneTwoTone,
    HomeOutlined,
    MobileOutlined
} from '@ant-design/icons';
import emailjs from 'emailjs-com';
// eslint-disable-next-line import/order
import Map from "../Map/map";
import 'antd/dist/antd.css';
import {RiLandscapeLine} from "react-icons/ri";
import {BsTelephone, BsTelephoneForward, BsTelephoneX} from "react-icons/bs";

const Contact = () => {
    if (!contact.email) return null;

    const [form] = Form.useForm();
    const [emailValid, setEmailValid] = useState(false);

    const onEmailChange = (e) => {
        const email = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailValid(emailRegex.test(email));
    };

    const onFinish = (values) => {
        console.log('Received values:', values);
        const serviceId = 'service_l9nz1vh';
        const userTemplateId = 'template_v1bkj6a'; // تمپلیت برای کاربر
        const selfTemplateId = 'template_hsbyky6'; // تمپلیت برای خودتان
        const userId = 'K_Bi6fWmnp-bJZlGX';

        // ارسال ایمیل به کاربر
        emailjs.send(serviceId, userTemplateId, {
            from_name: values.name,
            from_email: values.email,
            message: values.message,
        }, userId)
            .then((response) => {
                console.log('ایمیل برای کاربر ارسال شد', response.status, response.text);

                // ارسال ایمیل به خودتان
                emailjs.send(serviceId, selfTemplateId, {
                    from_name: values.name,
                    from_email: values.email,
                    message: values.message,
                    to_email: 'javataredu@gmail.com',
                }, userId)
                    // eslint-disable-next-line no-shadow
                    .then((response) => {
                        console.log('ایمیل برای خودتان ارسال شد', response.status, response.text);
                        notification.success({
                            message: 'ایمیل ارسال شد',
                            description: 'پیام شما با موفقیت ارسال شد.',
                            placement: 'bottomRight'
                        });
                        form.resetFields();
                    })
                    .catch((err) => {
                        console.error('ارسال ایمیل به خودتان با خطا مواجه شد', err);
                        notification.error({
                            message: 'خطا در ارسال ایمیل',
                            description: 'خطایی در ارسال پیام رخ داد. لطفا دوباره تلاش کنید.',
                            placement: 'bottomRight'
                        });
                    });
            })
            .catch((err) => {
                console.error('ارسال ایمیل به کاربر با خطا مواجه شد', err);
                notification.error({
                    message: 'خطا در ارسال ایمیل',
                    description: 'خطایی در ارسال پیام رخ داد. لطفا دوباره تلاش کنید.',
                    placement: 'bottomRight'
                });
            });
    };

    return (
        <section className='contact center' id='contact'>
            <h2 className='section__title sogand' style={{fontSize: 30}}>
                تماس با ما
            </h2>
            <div className="grid-container">
                <div className="map-column">
                    <Map/>
                </div>
                <div className="contact-info">
                    <h3 className="vazirabC">آدرس دفتر</h3>
                    <p><EnvironmentOutlined/> تهران،عباس آباد،خ شهید بهشتی،خ حسینی</p>
                    <p><PhoneOutlined/> دفتر: ٣٢٠٧ ٦٦٣٥ ٠٢١</p>
                    <p><MobileOutlined/> موبایل: ٩٢٥٥ ٢٢٤ ٠٩٣٨</p>
                    <p><MailOutlined/> ایمیل: <a
                        href="mailto:esmaeilsadeghijob@gmail.com">esmaeilsadeghijob@gmail.com</a></p>
                    {/* <p><TelegramIcon size={32} round /> <a href="https://t.me/computer_programming_school">گروه تلگرامی</a></p> */}
                </div>
                <div className="contact-form">
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            name="name"
                            rules={[{required: true, message: 'لطفاً نام و نام خانوادگی خود را وارد کنید'}]}
                            style={{marginBottom: '6px'}}
                        >
                            <Input placeholder="نام و نام خانوادگی"/>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[{required: true, message: 'لطفاً آدرس ایمیل خود را وارد کنید'}]}
                            style={{marginBottom: '6px'}}
                        >
                            <Input type="email" placeholder="آدرس ایمیل" onChange={onEmailChange}/>
                        </Form.Item>
                        <Form.Item
                            name="message"
                            rules={[{required: true, message: 'لطفاً متن پیام خود را وارد کنید'}]}
                            style={{marginBottom: '6px'}}
                        >
                            <Input.TextArea rows={4} placeholder="متن پیام"/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!emailValid}
                                className="submit-button"
                            >
                                ارسال
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </section>
    );
};

export default Contact;