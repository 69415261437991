import React, {useContext, useEffect} from 'react';
import './CoachingPath.css';
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import Footer from "../../Footer/Footer";
import {ThemeContext} from "../../../contexts/theme";
import HeaderStore from "../../Header/HeaderStore";
import NestedIcon from "../../Header/NestedIcon";

const CoachingPath = () => {
    const [{themeName}] = useContext(ThemeContext);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        });

        const steps = document.querySelectorAll('.step');
        steps.forEach((step) => {
            observer.observe(step);
        });
    }, []);

    return (
        <div id='top' className={`${themeName} app`}>
            <HeaderStore/>
            <div className="scene-icon-e ">
                <NestedIcon/>
            </div>
            <br/>
            <main>
                <div className="coaching-path">
                    <h1 className="sogandUser">مسیر کوچینگ برنامه‌نویسی</h1>
                    <div className="progress-bar">
                        <div className="step left">
                            <h2 className="lalezar">استعدادیابی</h2>
                            <p className="training">شروع با استعدادیابی و شناسایی علاقه‌ها و مهارت‌ها. این مرحله شامل
                                تست‌های روان‌شناختی و
                                مشاوره
                                برای تعیین مسیر مناسب است</p>
                            <a href="/question" className="lalezarI">
                                <p>انتقال به صفحه استدادیابی</p>
                            </a>
                        </div>
                        <div className="step right">
                            <h2 className="lalezar">مشاوره</h2>
                            <p className="training">مشاوره با متخصصین برای تعیین مسیر مناسب. مشاوره‌هایی در زمینه انتخاب
                                زبان برنامه‌نویسی،
                                ابزارها،
                                و روش‌های یادگیری</p>
                        </div>
                        <div className="step left">
                            <h2 className="lalezar">آموزش</h2>
                            <p className="training">یادگیری مفاهیم پایه و پیشرفته برنامه‌نویسی از طریق دوره‌های آموزشی،
                                ویدیوها، و پروژه‌های
                                عملی.
                                این مرحله شامل حل تمرین‌ها و پروژه‌های واقعی است</p>
                            <a href="/#projects" className="lalezarI">
                                <p>انتقال به صفحه آموزش ها</p>
                            </a>
                        </div>
                        <div className="step right">
                            <h2 className="lalezar">ارتقاء مهارت ها</h2>
                            <p className="training">آموزش مهارت‌های ارتباطی، کار تیمی و مدیریت زمان، اولویت‌بندی مناسب و
                                حل مسئله و هوش هیجانی برای موفقیت حرفه‌ای و شخصی به برنامه‌نویسان</p>
                        </div>
                        <div className="step left">
                            <h2 className="lalezar">روان‌شناختی</h2>
                            <p className="training">فراهم کردن مشاوره و حمایت روان‌شناختی برای کمک به برنامه‌نویسان در
                                مدیریت استرس و اضطراب</p>
                        </div>
                        <div className="step right">
                            <h2 className="lalezar">پروژه‌ها</h2>
                            <p className="training">انجام پروژه‌های عملی برای تقویت مهارت‌ها. شما پروژه‌هایی را از صفر
                                تا صد توسعه می‌دهید و
                                از
                                ابزارهای مدرن برنامه‌نویسی استفاده می‌کنید</p>
                        </div>
                        <div className="step left">
                            <h2 className="lalezar">آماده‌سازی مصاحبه</h2>
                            <p className="training">آماده‌سازی برای مصاحبه‌های شغلی شامل تمرین سوالات رایج، شبیه‌سازی
                                مصاحبه، و راهنمایی برای
                                ایجاد
                                رزومه و پرتفولیو</p>
                        </div>
                        <div className="step right">
                            <h2 className="lalezar">کاریابی</h2>
                            <p className="training">آماده‌سازی برای ورود به بازار کار و یافتن شغل. شامل راهنمایی در مورد
                                شبکه‌سازی، اپلای
                                کردن، و
                                آماده‌سازی برای ورود به بازار کار</p>
                        </div>
                        <div className="step left">
                            <h2 className="lalezar">مهاجرت</h2>
                            <p className="training">راهنمایی برای مهاجرت و کار در خارج از کشور. شامل اطلاعات در مورد
                                فرصت‌های شغلی بین‌المللی
                                و مراحل
                                مهاجرت</p>
                        </div>
                        <div className="step final">
                            <a href="/signup" className="signup-link">آماده‌ای؟ همین حالا ثبت‌نام کن و شروع کن</a>
                        </div>
                    </div>
                </div>
            </main>

            <ScrollToTop/>
            <Footer/>
        </div>
    );
};

export default CoachingPath;


