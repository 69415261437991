import React from 'react';
import {
    HomeOutlined,
    MobileOutlined,
    ShopOutlined,
    SolutionOutlined,
    StarOutlined,
    TrophyOutlined,
    UserAddOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import {header} from '../../portfolio';
import './Header.css';
import Navbar from "../Navbar/Navbar";
import DevIcon from "./DevIcone";

const Header = () => {
    const {homepage, title} = header;

    return (
        <header className='header center'>
            <div className='header-left'>
                {homepage ? (
                    <a href={homepage} className='link'>
                        <HomeOutlined/>
                    </a>
                ) : (
                    title
                )}
                <span className='space'/>
                <a href="tel:+989122273119" className='link'>
                    <MobileOutlined/>
                </a>
                <span className='space'/>
                <a href="/signup" className='link' title="ثبت‌نام">
                    <UserAddOutlined/>
                </a>
                <span className='space'/>
                <a href="/shop" className='link' title="فروشگاه">
                    <ShopOutlined style={{color: '#ff9900'}}/>
                </a>
                <span className='space'/>
                <a href="https://wa.me/989122273119" className='link' target="_blank" rel="noopener noreferrer">
                    <WhatsAppOutlined style={{color: '#25D366'}}/>
                </a>

                <span className='space'/>
                <a href="/question" className='link' title="استعدادیابی">
                    {/* <TrophyOutlined style={{color: '#FFD700'}}/> */}
                    <TrophyOutlined className='animated-icon' style={{color: '#FFD700'}}/>
                    {/* <StarOutlined style={{color: '#FFD700'}}/> */}
                    {/* <SolutionOutlined style={{color: '#FFD700'}}/> */}
                </a>
            </div>

            <div className='header-center'>
                <a href="/training-path" title="مسیر آموزش" className='icon-container'>
                    <DevIcon/>
                    <span className="lalezarI">کوچینگ</span>
                </a>
            </div>

            <div className='header-right'>
                <Navbar/>
            </div>
        </header>
    );
};

export default Header;
