import {Affix} from "antd";
import {useContext} from 'react'
import {ThemeContext} from '../../contexts/theme'
import Header from '../Header/Header'
import About from '../About/About'
import Projects from '../Projects/Projects'
import Skills from '../Skills/Skills'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'
import './Home.css'
import CarouselFerris from "../Carousel/CarouselFerris";
import AlertMessage from "../News/AlertMessage";

// eslint-disable-next-line import/order
// import {Link} from "react-router-dom";

const Home = () => {
    const [{themeName}] = useContext(ThemeContext)
    const d = new Date();
    const options = { weekday: 'long',  month: 'long', day: 'numeric' };
    const option = { year: 'numeric'};

    // let y;
    // let x;

    return (
        <div id='top' className={`${themeName} app`}>
            {/* <Affix style={{position: 'additions'}}> */}
            <Header/>
            {/* </Affix> */}

            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>
                <AlertMessage/>

                {/* <Link to="/software">software</Link> */}
                <CarouselFerris/>
                <About/>
                <Projects/>
                <Skills/>
                <Contact/>
            </main>

            <ScrollToTop/>
            <Footer/>
        </div>
    )
}

export default Home
