import {Carousel} from "antd";
import 'antd/dist/antd.css';
import {carousels} from '../../portfolio'

const CarouselFerris = () => (
    <Carousel effect="fade" autoplay autoplaySpeed="45" dotPosition="bottom"
              style={{textAlign: 'center', height: '200px', width: 'auto', margin: '0 auto', background: 'none'}}>
        {carousels.map((carousel) => (
            <div>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img
                    src={carousel.uri}
                    style={{display: "block", margin: "0 auto", height: '200px', width: '0 auto'}}/>
            </div>
        ))}
    </Carousel>
);

export default CarouselFerris
