// const questions = [
//     {
//         id: 1,
//         question: "چرا می‌خواهید برنامه‌نویسی یاد بگیرید؟",
//         options: ["برای تحت تأثیر قرار دادن دوستان", "برای اجتناب از سایر موضوعات", "برای گذراندن وقت", "برای حل مشکلات و ایجاد راه‌حل‌ها"],
//         answer: "برای حل مشکلات و ایجاد راه‌حل‌ها"
//     },
//     {
//         id: 2,
//         question: "نظرتان درباره حل معماها و مسائل منطقی چیست؟",
//         options: ["بی‌تفاوت هستم", "از آن‌ها اجتناب می‌کنم", "آن‌ها را خسته‌کننده می‌دانم", "از آن‌ها لذت می‌برم و چالش‌برانگیز می‌دانم"],
//         answer: "از آن‌ها لذت می‌برم و چالش‌برانگیز می‌دانم"
//     },
//     {
//         id: 3,
//         question: "رویکرد شما به یادگیری مفاهیم جدید چیست؟",
//         options: ["علاقه‌ای به یادگیری چیزهای جدید ندارم", "آن را دشوار و ناامیدکننده می‌دانم", "منتظر می‌مانم تا کسی به من آموزش دهد", "کنجکاو و فعال هستم"],
//         answer: "کنجکاو و فعال هستم"
//     },
//     {
//         id: 4,
//         question: "چگونه با اشتباهات برخورد می‌کنید؟",
//         options: ["آن‌ها را به عنوان فرصت‌های یادگیری می‌بینم", "به راحتی دلسرد می‌شوم", "آن‌ها را نادیده می‌گیرم", "دیگران را مقصر می‌دانم"],
//         answer: "آن‌ها را به عنوان فرصت‌های یادگیری می‌بینم"
//     },
//     {
//         id: 5,
//         question: "تجربه شما در استفاده از کامپیوتر چیست؟",
//         options: ["راحت هستم و به طور منظم از آن‌ها استفاده می‌کنم", "گاهی از آن‌ها استفاده می‌کنم", "به ندرت از آن‌ها استفاده می‌کنم", "از استفاده از آن‌ها اجتناب می‌کنم"],
//         answer: "راحت هستم و به طور منظم از آن‌ها استفاده می‌کنم"
//     },
//     {
//         id: 6,
//         question: "نظرتان درباره کار بر روی پروژه‌های بلندمدت چیست؟",
//         options: [" از تعهدات بلندمدت اجتناب می‌کنم", "به سرعت علاقه‌ام را از دست می‌دهم", "وظایف کوتاه را ترجیح می‌دهم", "هیجان‌زده و متعهد هستم"],
//         answer: "هیجان‌زده و متعهد هستم"
//     },
//     {
//         id: 7,
//         question: "نگرش شما نسبت به یادگیری مداوم چیست؟",
//         options: ["بی‌تفاوت هستم", "از یادگیری چیزهای جدید اجتناب می‌کنم", "فقط در صورت لزوم یاد می‌گیرم", "مشتاق یادگیری و بهبود هستم"],
//         answer: "مشتاق یادگیری و بهبود هستم"
//     },
//     {
//         id: 8,
//         question: "چگونه به حل مسئله نزدیک می‌شوید؟",
//         options: ["مسائل را به بخش‌های کوچکتر تقسیم می‌کنم", "به دنبال راه‌حل‌های سریع هستم", "از مسائل اجتناب می‌کنم", "به دیگران برای حل مسائل تکیه می‌کنم"],
//         answer: "به دیگران برای حل مسائل تکیه می‌کنم"
//     },
//     {
//         id: 9,
//         question: "سطح صبر شما هنگام کار بر روی وظایف پیچیده چگونه است؟",
//         options: ["از وظایف پیچیده اجتناب می‌کنم", "به سرعت تسلیم می‌شوم", "به راحتی ناامید می‌شوم", "صبور و پایدار هستم"],
//         answer: "صبور و پایدار هستم"
//     },
//     {
//         id: 10,
//         question: "نظرتان درباره همکاری با دیگران در پروژه‌ها چیست؟",
//         options: ["از همکاری اجتناب می‌کنم", "آن را چالش‌برانگیز می‌دانم", "ترجیح می‌دهم به تنهایی کار کنم", "از کار تیمی و همکاری لذت می‌برم"],
//         answer: "از کار تیمی و همکاری لذت می‌برم"
//     },
//     // {
//     //     id: 11,
//     //     question: "نظرتان درباره یادگیری فناوری‌ها و ابزارهای جدید چیست؟",
//     //     options: ["از یادگیری فناوری‌های جدید اجتناب می‌کنم", "آن را طاقت‌فرسا می‌دانم", "فقط در صورت لزوم یاد می‌گیرم", "هیجان‌زده و مشتاق یادگیری هستم"],
//     //     answer: "هیجان‌زده و مشتاق یادگیری هستم"
//     // },
//     // {
//     //     id: 12,
//     //     question: "رویکرد شما به رفع مشکلات فنی چیست؟",
//     //     options: ["مشکلات را نادیده می‌گیرم", "بلافاصله درخواست کمک می‌کنم", "تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم", "ناامید می‌شوم و تسلیم می‌شوم"],
//     //     answer: "تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم"
//     // },
//     // {
//     //     id: 13,
//     //     question: "نظر شما در مورد یادگیری فناوری‌ها و ابزارهای جدید چیست؟",
//     //     options: ["از یادگیری فناوری‌های جدید اجتناب می‌کنم", "آن را طاقت‌فرسا می‌دانم", "فقط در صورت لزوم یاد می‌گیرم", "من هیجان‌زده و مشتاق یادگیری هستم"],
//     //     answer: "من هیجان‌زده و مشتاق یادگیری هستم"
//     // },
//     // {
//     //     id: 14,
//     //     question: "رویکرد شما برای رفع مشکلات فنی چیست؟ ",
//     //     options: ["ناامید می‌شوم و تسلیم می‌شوم", "مشکلات را نادیده می‌گیرم ", "بلافاصله درخواست کمک می‌کنم ", "تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم "],
//     //     answer: "تحقیق می‌کنم و راه‌حل‌های مختلف را امتحان می‌کنم "
//     // },
//     // {
//     //     id: 15,
//     //     question: "چگونه زمان خود را هنگام کار بر روی پروژه‌ها مدیریت می‌کنید؟",
//     //     options: ["به تعویق می‌اندازم", "در مدیریت زمان مشکل دارم", "وظایف را به محض رسیدن انجام می‌دهم ", "برنامه‌ریزی می‌کنم و وظایف را به‌طور مؤثر اولویت‌بندی می‌کنم "],
//     //     answer: "برنامه‌ریزی می‌کنم و وظایف را به‌طور مؤثر اولویت‌بندی می‌کنم "
//     // },
//     // {
//     //     id: 16,
//     //     question: "چه چیزی شما را برای انجام وظایف چالش‌برانگیز انگیزه می‌دهد؟",
//     //     options: ["از وظایف چالش‌برانگیز اجتناب می‌کنم", "اجتناب از پیامدهای منفی", "پاداش‌های خارجی", "حس موفقیت"],
//     //     answer: "حس موفقیت"
//     // },
//     // {
//     //     id: 17,
//     //     question: "چگونه از اشتباهات خود یاد می‌گیرید؟",
//     //     options: ["عوامل خارجی را مقصر می‌دانم", "احساس دلسردی می‌کنم", "سعی می‌کنم آن‌ها را فراموش کنم", "آن‌ها را تحلیل می‌کنم و از آن‌ها درس می‌گیرم"],
//     //     answer: "آن‌ها را تحلیل می‌کنم و از آن‌ها درس می‌گیرم"
//     // },
//     // {
//     //     id: 18,
//     //     question: "تجربه شما با تفکر منطقی و استدلال چگونه است؟",
//     //     options: ["از آن اجتناب می‌کنم", "با آن مشکل دارم", "آن را به‌طور متوسط چالش‌برانگیز می‌دانم", "از آن لذت می‌برم و در آن مهارت دارم"],
//     //     answer: "از آن لذت می‌برم و در آن مهارت دارم"
//     // },
//     // {
//     //     id: 19,
//     //     question: "نظر شما در مورد پیروی از دستورالعمل‌های دقیق چیست؟",
//     //     options: ["ترجیح می‌دهم خودم راه‌حل پیدا کنم", "آن را خسته‌کننده می‌دانم", "آن‌ها را به‌طور سطحی مرور می‌کنم", "با دقت از آن‌ها پیروی می‌کنم"],
//     //     answer: "با دقت از آن‌ها پیروی می‌کنم"
//     // },
//     // {
//     //     id: 20,
//     //     question: "رویکرد شما برای بهبود مستمر چیست؟",
//     //     options: ["بی‌تفاوت هستم", "در برابر تغییر مقاومت می‌کنم ", "فقط در صورت لزوم بهبود می‌بخشم", "فعالانه بازخورد می‌گیرم و بهبود می‌بخشم "],
//     //     answer: "فعالانه بازخورد می‌گیرم و بهبود می‌بخشم "
//     // },
//     // {
//     //     id: 21,
//     //     question: "چگونه با مفاهیم پیچیده و انتزاعی برخورد می‌کنید؟",
//     //     options: ["از آن‌ها اجتناب می‌کنم", "با آن‌ها مشکل دارم", "آن‌ها را چالش‌برانگیز اما قابل مدیریت می‌دانم", "آن‌ها را به بخش‌های ساده‌تر تقسیم می‌کنم"],
//     //     answer: "آن‌ها را به بخش‌های ساده‌تر تقسیم می‌کنم"
//     // },
//     // {
//     //     id: 22,
//     //     question: "نظر شما در مورد آزمایش ایده‌های جدید چیست؟",
//     //     options: ["از آزمایش اجتناب می‌کنم", "آن را پرخطر می‌دانم", "روش‌های امتحان‌شده و واقعی را ترجیح می‌دهم", "من باز و مایل به آزمایش هستم"],
//     //     answer: "من باز و مایل به آزمایش هستم"
//     // },
//     // {
//     //     id: 23,
//     //     question: "چه احساسی نسبت به کار با ساختارهای داده انتزاعی مانند درخت‌ها و گراف‌ها دارید؟",
//     //     options: ["از آنها اجتناب می‌کنم", "آنها را گیج‌کننده می‌دانم", "مایل به یادگیری درباره آنها هستم", "آنها را جذاب و چالش‌برانگیز می‌دانم"],
//     //     answer: "آنها را جذاب و چالش‌برانگیز می‌دانم"
//     // },
//     // {
//     //     id: 24,
//     //     question: "رویکرد شما به یادگیری از منابع و آموزش‌های آنلاین چیست؟",
//     //     options: ["یادگیری حضوری را ترجیح می‌دهم", "آنها را دشوار می‌دانم", "گاهی از آنها استفاده می‌کنم", "فعالانه به دنبال آنها می‌گردم و همراهی می‌کنم"],
//     //     answer: "فعالانه به دنبال آنها می‌گردم و همراهی می‌کنم"
//     // },
//     // {
//     //     id: 25,
//     //     question: "چگونه با وظایف تکراری برخورد می‌کنید؟ ",
//     //     options: ["به دنبال راه‌هایی برای خودکارسازی آنها هستم", "از آنها اجتناب می‌کنم", "به سرعت خسته می‌شوم", "آنها را در صورت نیاز انجام می‌دهم"],
//     //     answer: "به دنبال راه‌هایی برای خودکارسازی آنها هستم"
//     // },
//     // {
//     //     id: 26,
//     //     question: "تجربه شما با مفاهیم ریاضی در برنامه‌نویسی چگونه است؟",
//     //     options: ["از آنها اجتناب می‌کنم", "آنها را چالش‌برانگیز می‌دانم", "می‌توانم مفاهیم پایه را مدیریت کنم", "با آنها راحت هستم و از استفاده از آنها لذت می‌برم"],
//     //     answer: "با آنها راحت هستم و از استفاده از آنها لذت می‌برم"
//     // },
//     // {
//     //     id: 27,
//     //     question: "چه احساسی نسبت به همکاری در پروژه‌های متن‌باز دارید؟",
//     //     options: ["ترجیح می‌دهم روی پروژه‌های خصوصی کار کنم", "آن را ترسناک می‌دانم", "علاقه‌مند هستم اما نمی‌دانم از کجا شروع کنم", "هیجان‌زده و مشتاق به مشارکت هستم"],
//     //     answer: "هیجان‌زده و مشتاق به مشارکت هستم"
//     // },
//     // {
//     //     id: 28,
//     //     question: "رویکرد شما به به‌روز ماندن با روندها و فناوری‌های جدید برنامه‌نویسی چیست؟",
//     //     options: ["علاقه‌ای ندارم", "آن را طاقت‌فرسا می‌دانم", "در صورت نیاز درباره آنها یاد می‌گیرم", "فعالانه اخبار و به‌روزرسانی‌های صنعت را دنبال می‌کنم"],
//     //     answer: "فعالانه اخبار و به‌روزرسانی‌های صنعت را دنبال می‌کنم"
//     // },
//     // {
//     //     id: 29,
//     //     question: "چه احساسی نسبت به نوشتن مستندات برای کد خود دارید؟",
//     //     options: ["از آن اجتناب می‌کنم", "آن را خسته‌کننده می‌دانم", "در صورت نیاز آن را انجام می‌دهم", "آن را بخشی ضروری از فرآیند می‌دانم"],
//     //     answer: "آن را بخشی ضروری از فرآیند می‌دانم"
//     // },
//     // {
//     //     id: 30,
//     //     question: "چقدر به کار با پروژه‌های منبع باز علاقه دارید؟",
//     //     options: ["ترجیح می‌دهم روی پروژه‌های خصوصی کار کنم", "کار با پروژه‌های منبع باز را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم با پروژه‌های منبع باز کار کنم ولی کمی زمان می‌برد", " از کار با پروژه‌های منبع باز لذت می‌برم و همیشه به دنبال مشارکت هستم"],
//     //     answer: " از کار با پروژه‌های منبع باز لذت می‌برم و همیشه به دنبال مشارکت هستم"
//     // },
//     // {
//     //     id: 31,
//     //     question: "چگونه به یادگیری یک زبان برنامه‌نویسی جدید نزدیک می‌شوید؟",
//     //     options: ["به سرعت شروع به کدنویسی می‌کنم", "آموزش‌ها و دوره‌های", "آن را چالش‌برانگیز می‌دانم", "از یادگیری زبان‌های جدید اجتناب می‌کنم"],
//     //     answer: "آموزش‌ها و دوره‌های"
//     // },
//     // {
//     //     id: 32,
//     //     question: "نگرش شما نسبت به تست کد خود چیست؟",
//     //     options: ["آن را یک مرحله حیاتی می‌دانم", "در صورت نیاز آن را انجام می‌دهم", "آن را وقت‌گیر می دانم", "از آن اجتناب می‌کنم"],
//     //     answer: "آن را یک مرحله حیاتی می‌دانم"
//     // },
//     // {
//     //     id: 33,
//     //     question: "چقدر به حل معماها و مشکلات منطقی علاقه دارید؟",
//     //     options: ["اصلاً لذت نمی‌برم", " چالش‌برانگیز است ولی جالب", "گاهی از آن لذت می‌برم", "عاشقش هستم و اغلب انجام می‌دهم"],
//     //     answer: "عاشقش هستم و اغلب انجام می‌دهم"
//     // },
//     // {
//     //     id: 34,
//     //     question: "وقتی با یک مشکل دشوار مواجه می‌شوید، چه رویکردی دارید؟",
//     //     options: ["ناامید می‌شوم و تسلیم می‌شوم", "از دیگران کمک می‌گیرم", "به دنبال مشکلات مشابه می‌گردم و می‌بینم چگونه حل شده‌اند", "آن را به بخش‌های کوچکتر تقسیم کرده و هر یک را حل می‌کنم"],
//     //     answer: "آن را به بخش‌های کوچکتر تقسیم کرده و هر یک را حل می‌کنم"
//     // },
//     // {
//     //     id: 35,
//     //     question: "چگونه با وظایف تکراری برخورد می‌کنید؟",
//     //     options: [" تا حد ممکن از آنها اجتناب می‌کنم", "سعی می‌کنم راهی کارآمدتر برای انجام آنها پیدا کنم", "آنها را انجام می‌دهم ولی خسته‌کننده هستند", "به دنبال راه‌هایی برای خودکارسازی آنها هستم"],
//     //     answer: "به دنبال راه‌هایی برای خودکارسازی آنها هستم"
//     // },
//     // {
//     //     id: 36,
//     //     question: "چقدر با یادگیری فناوری‌ها و ابزارهای جدید راحت هستید؟",
//     //     options: ["ناراحت، آن را طاقت‌فرسا می‌دانم", "تا حدودی راحت، ولی ترجیح می‌دهم به چیزهایی که می‌دانم پایبند باشم", "راحت، ولی کمی زمان می‌برد", "بسیار راحت، از یادگیری چیزهای جدید لذت می‌برم"],
//     //     answer: "بسیار راحت، از یادگیری چیزهای جدید لذت می‌برم"
//     // },
//     // {
//     //     id: 37,
//     //     question: "چقدر به کار بر روی پروژه‌هایی که نیاز به دقت دارند علاقه دارید؟",
//     //     options: ["با آن مشکل دارم و ترجیح می‌دهم وظایف کلی‌تر را انجام دهم", "چالش‌برانگیز است ولی قابل مدیریت", "می‌توانم انجام دهم، ولی مورد علاقه‌ام نیست", "از آن لذت می‌برم و بسیار دقیق هستم"],
//     //     answer: "از آن لذت می‌برم و بسیار دقیق هستم"
//     // },
//     // {
//     //     id: 38,
//     //     question: "واکنش شما به مواجهه با خطاها یا باگ‌ها در کارتان چیست؟",
//     //     options: ["ناامید می‌شوم و انگیزه‌ام را از دست می‌دهم", "سعی می‌کنم سریعاً آن را رفع کرده و ادامه دهم", "آن را خسته‌کننده ولی ضروری می‌دانم", "آن را به عنوان یک چالش می‌بینم و از رفع باگ لذت می‌برم"],
//     //     answer: "آن را به عنوان یک چالش می‌بینم و از رفع باگ لذت می‌برم"
//     // },
//     // {
//     //     id: 39,
//     //     question: "چگونه ترجیح می‌دهید مهارت‌های جدید را یاد بگیرید؟",
//     //     options: ["با خواندن کتاب‌ها و مقالات", "با شرکت در کلاس‌ها یا دوره‌های رسمی", "با دنبال کردن آموزش‌ها و راهنماها", "از طریق تمرین عملی و آزمایش"],
//     //     answer: "از طریق تمرین عملی و آزمایش"
//     // },
//     // {
//     //     id: 40,
//     //     question: "چقدر به همکاری با دیگران در پروژه‌ها علاقه دارید؟",
//     //     options: ["ترجیح می‌دهم به صورت مستقل کار کنم", "آن را مفید ولی گاهی چالش‌برانگیز می‌دانم", "می‌توانم انجام دهم، ولی ترجیح می‌دهم تنها کار کنم", "از آن لذت می‌برم و مفید می‌دانم"],
//     //     answer: "از آن لذت می‌برم و مفید می‌دانم"
//     // },
//     // {
//     //     id: 41,
//     //     question: "چقدر به بهبود مداوم مهارت‌های خود علاقه دارید؟",
//     //     options: ["از سطح مهارت فعلی خود راضی هستم", "فقط وقتی لازم باشد بهبود پیدا می‌کنم", "سعی می‌کنم وقتی زمان دارم بهبود پیدا کنم", "همیشه به دنبال راه‌هایی برای بهبود هستم"],
//     //     answer: "همیشه به دنبال راه‌هایی برای بهبود هستم"
//     // },
//     // {
//     //     id: 42,
//     //     question: "چگونه از اشتباهات خود یاد می‌گیرید؟",
//     //     options: ["یادگیری از اشتباهات برایم سخت است", "احساس ناامیدی می‌کنم ولی سعی می‌کنم ادامه دهم", "سعی می‌کنم از تکرار اشتباهات جلوگیری کنم", "اشتباهاتم را تحلیل کرده و از آنها یاد می‌گیرم"],
//     //     answer: "اشتباهاتم را تحلیل کرده و از آنها یاد می‌گیرم"
//     // },
//     // {
//     //     id: 43,
//     //     question: "چقدر به آزمایش ایده‌های جدید علاقه دارید؟",
//     //     options: ["تا حد ممکن از آزمایش اجتناب می‌کنم", "ترجیح می‌دهم به روش‌های اثبات شده پایبند باشم", "به ایده‌های جدید باز هستم ولی محتاط", "عاشق آزمایش و امتحان چیزهای جدید هستم"],
//     //     answer: "عاشق آزمایش و امتحان چیزهای جدید هستم"
//     // },
//     // {
//     //     id: 44,
//     //     question: "چگونه با بازخورد در مورد کارتان برخورد می‌کنید؟",
//     //     options: ["بازخورد را دلسردکننده می‌دانم", "احساس دفاعی می‌کنم ولی سعی می‌کنم در نظر بگیرم", "بازخورد را می‌پذیرم ولی اجرای آن برایم سخت است", "از بازخورد قدردانی کرده و از آن برای بهبود استفاده می‌کنم"],
//     //     answer: "از بازخورد قدردانی کرده و از آن برای بهبود استفاده می‌کنم"
//     // },
//     // {
//     //     id: 45,
//     //     question: "چگونه با فشار و ضرب‌الاجل‌ها برخورد می‌کنید؟",
//     //     options: ["می‌توانم با فشار و ضرب‌الاجل‌ها کنار بیایم ولی کمی استرس‌زا است", "با فشار و ضرب‌الاجل‌ها مشکل دارم و ترجیح می‌دهم به برنامه‌های بدون فشار پایبند باشم", "فشار و ضرب‌الاجل‌ها را چالش‌برانگیز ولی قابل مدیریت می‌دانم", "به خوبی با فشار و ضرب‌الاجل‌ها کنار می‌آیم و کارم را به موقع انجام می‌دهم"],
//     //     answer: "به خوبی با فشار و ضرب‌الاجل‌ها کنار می‌آیم و کارم را به موقع انجام می‌دهم"
//     // },
//     // {
//     //     id: 46,
//     //     question: "چقدر به کار با پروژه‌های مبتنی بر هوش مصنوعی علاقه دارید؟",
//     //     options: ["ترجیح می‌دهم روی پروژه‌های سنتی‌تر کار کنم", "کار با پروژه‌های مبتنی بر هوش مصنوعی را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم با پروژه‌های مبتنی بر هوش مصنوعی کار کنم ولی کمی زمان می‌برد", "از کار با پروژه‌های مبتنی بر هوش مصنوعی لذت می‌برم و همیشه به دنبال یادگیری هستم"],
//     //     answer: "از کار با پروژه‌های مبتنی بر هوش مصنوعی لذت می‌برم و همیشه به دنبال یادگیری هستم"
//     // },
//     // {
//     //     id: 47,
//     //     question: "چقدر به کار با ابزارهای توسعه نرم‌افزار علاقه دارید؟",
//     //     options: ["ترجیح می‌دهم به روش‌های سنتی توسعه نرم‌افزار پایبند باشم", "ابزارهای توسعه نرم‌افزار را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم با ابزارهای توسعه نرم‌افزار کار کنم ولی همیشه راحت نیست", "از کار با ابزارهای توسعه نرم‌افزار لذت می‌برم و آنها را مفید می‌دانم"],
//     //     answer: "از کار با ابزارهای توسعه نرم‌افزار لذت می‌برم و آنها را مفید می‌دانم"
//     // },
//     // {
//     //     id: 48,
//     //     question: "چقدر به کار با تیم‌های بین‌المللی علاقه دارید؟",
//     //     options: ["ترجیح می‌دهم با تیم‌های محلی کار کنم", "کار با تیم‌های بین‌المللی را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم با تیم‌های بین‌المللی کار کنم ولی گاهی چالش‌برانگیز است", "از کار با تیم‌های بین‌المللی لذت می‌برم و آن را مفید می‌دانم"],
//     //     answer: "از کار با تیم‌های بین‌المللی لذت می‌برم و آن را مفید می‌دانم"
//     // },
//     // {
//     //     id: 49,
//     //     question: "چگونه با پروژه‌هایی که نیاز به خلاقیت دارند برخورد می‌کنید؟",
//     //     options: ["ترجیح می‌دهم روی پروژه‌های ساختاریافته کار کنم", "پروژه‌های خلاقانه را چالش‌برانگیز ولی جالب می‌دانم", "می‌توانم پروژه‌های خلاقانه را مدیریت کنم ولی همیشه ایده‌های جدید ندارم", "از پروژه‌های خلاقانه لذت می‌برم و ایده‌های جدید ارائه می‌دهم"],
//     //     answer: "از پروژه‌های خلاقانه لذت می‌برم و ایده‌های جدید ارائه می‌دهم"
//     // },
//     // {
//     //     id: 50,
//     //     question: "چقدر به کار با ابزارهای مدیریت پروژه علاقه دارید؟",
//     //     options: ["می‌توانم با ابزارهای مدیریت پروژه کار کنم ولی همیشه راحت نیست", "ترجیح می‌دهم به روش‌های سنتی مدیریت پروژه پایبند باشم", "ابزارهای مدیریت پروژه را چالش‌برانگیز ولی جالب می‌دانم", "از کار با ابزارهای مدیریت پروژه لذت می‌برم و آنها را مفید می‌دانم"],
//     //     answer: "از کار با ابزارهای مدیریت پروژه لذت می‌برم و آنها را مفید می‌دانم"
//     // },
//     // {
//     //     id: 0,
//     //     question: "",
//     //     options: ["", "", "", ""],
//     //     answer: ""
//     // }
// ]
//
//
// // eslint-disable-next-line import/prefer-default-export
// export {questions}


const questions = [
    {
        id: 1,
        question: "چرا می‌خواهید برنامه‌نویسی یاد بگیرید؟",
        options: ["برای تحت تأثیر قرار دادن دوستان", "برای اجتناب از سایر موضوعات", "برای گذراندن وقت", "برای حل مشکلات و ایجاد راه‌حل‌ها"],
        answer: "برای حل مشکلات و ایجاد راه‌حل‌ها"
    },
    {
        id: 2,
        question: "نظرتان درباره حل معماها و مسائل منطقی چیست؟",
        options: ["بی‌تفاوت هستم", "از آن‌ها اجتناب می‌کنم", "آن‌ها را خسته‌کننده می‌دانم", "از آن‌ها لذت می‌برم و چالش‌برانگیز می‌دانم"],
        answer: "از آن‌ها لذت می‌برم و چالش‌برانگیز می‌دانم"
    },
    {
        id: 3,
        question: "رویکرد شما به یادگیری مفاهیم جدید چیست؟",
        options: ["علاقه‌ای به یادگیری چیزهای جدید ندارم", "آن را دشوار و ناامیدکننده می‌دانم", "منتظر می‌مانم تا کسی به من آموزش دهد", "کنجکاو و فعال هستم"],
        answer: "کنجکاو و فعال هستم"
    },
    {
        id: 4,
        question: "چگونه با اشتباهات برخورد می‌کنید؟",
        options: ["آن‌ها را به عنوان فرصت‌های یادگیری می‌بینم", "به راحتی دلسرد می‌شوم", "آن‌ها را نادیده می‌گیرم", "دیگران را مقصر می‌دانم"],
        answer: "آن‌ها را به عنوان فرصت‌های یادگیری می‌بینم"
    },
    {
        id: 5,
        question: "تجربه شما در استفاده از کامپیوتر چیست؟",
        options: ["راحت هستم و به طور منظم از آن‌ها استفاده می‌کنم", "گاهی از آن‌ها استفاده می‌کنم", "به ندرت از آن‌ها استفاده می‌کنم", "از استفاده از آن‌ها اجتناب می‌کنم"],
        answer: "راحت هستم و به طور منظم از آن‌ها استفاده می‌کنم"
    },
    {
        id: 6,
        question: "نظرتان درباره کار بر روی پروژه‌های بلندمدت چیست؟",
        options: [" از تعهدات بلندمدت اجتناب می‌کنم", "به سرعت علاقه‌ام را از دست می‌دهم", "وظایف کوتاه را ترجیح می‌دهم", "هیجان‌زده و متعهد هستم"],
        answer: "هیجان‌زده و متعهد هستم"
    },
    {
        id: 7,
        question: "نگرش شما نسبت به یادگیری مداوم چیست؟",
        options: ["بی‌تفاوت هستم", "از یادگیری چیزهای جدید اجتناب می‌کنم", "فقط در صورت لزوم یاد می‌گیرم", "مشتاق یادگیری و بهبود هستم"],
        answer: "مشتاق یادگیری و بهبود هستم"
    },
    {
        id: 8,
        question: "چگونه به حل مسئله نزدیک می‌شوید؟",
        options: ["مسائل را به بخش‌های کوچکتر تقسیم می‌کنم", "به دنبال راه‌حل‌های سریع هستم", "از مسائل اجتناب می‌کنم", "به دیگران برای حل مسائل تکیه می‌کنم"],
        answer: "مسائل را به بخش‌های کوچکتر تقسیم می‌کنم"
    },
    {
        id: 9,
        question: "سطح صبر شما هنگام کار بر روی وظایف پیچیده چگونه است؟",
        options: ["از وظایف پیچیده اجتناب می‌کنم", "به سرعت تسلیم می‌شوم", "به راحتی ناامید می‌شوم", "صبور و پایدار هستم"],
        answer: "صبور و پایدار هستم"
    },
    {
        id: 10,
        question: "نظرتان درباره همکاری با دیگران در پروژه‌ها چیست؟",
        options: ["از همکاری اجتناب می‌کنم", "آن را چالش‌برانگیز می‌دانم", "ترجیح می‌دهم به تنهایی کار کنم", "از کار تیمی و همکاری لذت می‌برم"],
        answer: "از کار تیمی و همکاری لذت می‌برم"
    },
    {
        id: 11,
        question: "چقدر به تنظیمات و جزییات کوچک توجه می‌کنید؟",
        options: ["توجه کمی دارم", "گاه‌گاهی توجه دارم", "تا حدودی توجه دارم", "توجه بسیار زیادی دارم"],
        answer: "توجه بسیار زیادی دارم"
    },
    {
        id: 12,
        question: "چقدر به دنبال راه‌حل‌های خلاقانه برای مشکلات هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "همیشه"],
        answer: "همیشه"
    },
    {
        id: 13,
        question: "نظرتان درباره یادگیری زبان‌های جدید برنامه‌نویسی چیست؟",
        options: ["علاقه‌ای ندارم", "دشوار است", "به ندرت یاد می‌گیرم", "علاقه‌مند و مشتاق هستم"],
        answer: "علاقه‌مند و مشتاق هستم"
    },
    {
        id: 14,
        question: "چگونه به برنامه‌ریزی و زمان‌بندی کارها نزدیک می‌شوید؟",
        options: ["بی‌برنامه پیش می‌روم", "به ندرت زمان‌بندی می‌کنم", "گاه‌گاهی برنامه‌ریزی می‌کنم", "به دقت برنامه‌ریزی می‌کنم"],
        answer: "به دقت برنامه‌ریزی می‌کنم"
    },
    {
        id: 15,
        question: "چگونه با بازخورد منفی برخورد می‌کنید؟",
        options: ["آن را نادیده می‌گیرم", "به راحتی دلسرد می‌شوم", "سعی می‌کنم بهبود یابم", "بازخوردها را تحلیل و استفاده می‌کنم"],
        answer: "بازخوردها را تحلیل و استفاده می‌کنم"
    },
    {
        id: 16,
        question: "چقدر به دقت و صحت کدهای خود اهمیت می‌دهید؟",
        options: ["خیلی کم", "گاه‌گاهی", "تا حدودی", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 17,
        question: "چقدر به دنبال به‌روزرسانی مهارت‌های برنامه‌نویسی خود هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "همیشه"],
        answer: "همیشه"
    },
    {
        id: 18,
        question: "چقدر به توانایی کار تحت فشار اعتقاد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 19,
        question: "چقدر در حل مشکلات پیچیده خودکفا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 20,
        question: "نظرتان درباره استفاده از منابع آنلاین برای یادگیری چیست؟",
        options: ["بی‌تفاوت", "گاه‌گاهی استفاده می‌کنم", "به ندرت استفاده می‌کنم", "همیشه استفاده می‌کنم"],
        answer: "همیشه استفاده می‌کنم"
    },
    {
        id: 21,
        question: "چگونه با مشکلات ناگهانی در کد مواجه می‌شوید؟",
        options: ["دلسرد می‌شوم", "به دنبال راه‌حل سریع می‌گردم", "تحلیل و بررسی می‌کنم", "از کمک دیگران استفاده می‌کنم"],
        answer: "تحلیل و بررسی می‌کنم"
    },
    {
        id: 22,
        question: "چقدر به همکاری در تیم‌های برنامه‌نویسی علاقه دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 23,
        question: "چقدر به دنبال چالش‌های جدید در برنامه‌نویسی هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "همیشه"],
        answer: "همیشه"
    },
    {
        id: 24,
        question: "چقدر به توانایی تفکر منطقی خود در برنامه‌نویسی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 25,
        question: "چقدر به توانایی خواندن و درک مستندات فنی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 26,
        question: "چقدر به بهینه‌سازی کدهای خود اهمیت می‌دهید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 27,
        question: "چقدر به دنبال یادگیری الگوریتم‌ها و ساختارهای داده هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 28,
        question: "چقدر به توانایی اشکال‌زدایی کدهای خود اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 29,
        question: "چقدر به توانایی ایجاد مستندات کدهای خود اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 30,
        question: "چقدر به استفاده از ابزارهای مدیریت پروژه‌ها علاقه دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 31,
        question: "چقدر به توانایی تجزیه و تحلیل مشکلات و یافتن راه‌حل‌های مناسب اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 32,
        question: "چقدر به استفاده از سیستم‌های کنترل نسخه (مانند Git) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 33,
        question: "چقدر به توانایی کار بر روی پروژه‌های گروهی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 34,
        question: "چقدر به توانایی مدیریت زمان و اولویت‌بندی وظایف خود اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 35,
        question: "چقدر به توانایی استفاده از ابزارهای برنامه‌نویسی (مانند IDEها) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 36,
        question: "چقدر به توانایی استفاده از ابزارهای خودکارسازی (مانند CI/CD) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 37,
        question: "چقدر به توانایی یادگیری سریع تکنولوژی‌های جدید اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 38,
        question: "چقدر به توانایی برقراری ارتباط با تیم‌های دیگر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 39,
        question: "چقدر به توانایی درک نیازهای کاربران و ایجاد راه‌حل‌های مناسب اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 40,
        question: "چقدر به توانایی مدیریت ریسک‌ها و چالش‌های پروژه‌ها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 41,
        question: "چقدر به توانایی مدیریت منابع پروژه‌ها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 42,
        question: "چقدر به توانایی استفاده از تکنولوژی‌های جدید اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 43,
        question: "چقدر به توانایی تحلیل و بهینه‌سازی پایگاه‌های داده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 44,
        question: "چقدر به توانایی مدیریت تیم‌های برنامه‌نویسی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 45,
        question: "چقدر به توانایی حل مشکلات نرم‌افزاری پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 46,
        question: "چقدر به توانایی اجرای پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 47,
        question: "چقدر به توانایی نوشتن کدهای قابل تست و نگهداری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 48,
        question: "چقدر به توانایی رفع خطاهای کدهای دیگران اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 49,
        question: "چقدر به توانایی پیاده‌سازی الگوریتم‌های پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 50,
        question: "چقدر به توانایی درک و تحلیل کدهای نوشته شده توسط دیگران اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 51,
        question: "چقدر به توانایی یادگیری از اشتباهات اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 52,
        question: "چقدر به توانایی ایجاد کدهای بهینه و سریع اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 53,
        question: "چقدر به توانایی استفاده از روش‌های مختلف اشکال‌زدایی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 54,
        question: "چقدر به توانایی نوشتن تست‌های خودکار برای کدهای خود اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 55,
        question: "چقدر به توانایی مدیریت فرآیندهای توسعه نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 56,
        question: "چقدر به توانایی استفاده از ابزارهای مانیتورینگ و دیباگینگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 57,
        question: "چقدر به توانایی بهبود مستمر فرآیندهای توسعه نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 58,
        question: "چقدر به توانایی مدیریت تغییرات و به‌روزرسانی‌ها در نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 59,
        question: "چقدر به توانایی تحلیل و بهینه‌سازی عملکرد نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 60,
        question: "چقدر به توانایی استفاده از روش‌های طراحی نرم‌افزار (مانند طراحی شی‌گرا) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 61,
        question: "چقدر به توانایی ایجاد و مدیریت مستندات پروژه اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 62,
        question: "چقدر به توانایی استفاده از متدولوژی‌های مختلف توسعه نرم‌افزار (مانند Agile) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 63,
        question: "چقدر به توانایی مدیریت زمان‌بندی‌های پروژه‌ها و تحویل به موقع اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 64,
        question: "چقدر به توانایی ایجاد نرم‌افزارهای قابل اعتماد و پایدار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 65,
        question: "چقدر به توانایی کار با فناوری‌های ابری (مانند AWS یا Azure) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 66,
        question: "چقدر به توانایی ایجاد و مدیریت شبکه‌های کامپیوتری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 67,
        question: "چقدر به توانایی کار با سیستم‌های مدیریت محتوای وب (مانند WordPress) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 68,
        question: "چقدر به توانایی ایجاد و مدیریت اپلیکیشن‌های موبایل اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 69,
        question: "چقدر به توانایی استفاده از فریم‌ورک‌های برنامه‌نویسی (مانند React یا Angular) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 70,
        question: "چقدر به توانایی ایجاد و مدیریت تست‌های نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 71,
        question: "چقدر به توانایی استفاده از سرویس‌های ابری برای توسعه و مدیریت اپلیکیشن‌ها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 72,
        question: "چقدر به توانایی استفاده از روش‌های طراحی رابط کاربری و تجربه کاربری (UI/UX) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 73,
        question: "چقدر به توانایی مدیریت چرخه حیات توسعه نرم‌افزار (SDLC) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 74,
        question: "چقدر به توانایی پیاده‌سازی اصول امنیت نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 75,
        question: "چقدر به توانایی استفاده از روش‌های مختلف مهندسی نرم‌افزار (مانند طراحی شی‌گرا) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 76,
        question: "چقدر به توانایی مدیریت پروژه‌های نرم‌افزاری به‌صورت تیمی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 77,
        question: "چقدر به توانایی نوشتن کدهای تمیز و قابل نگهداری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 78,
        question: "چقدر به توانایی استفاده از الگوریتم‌های جستجو و مرتب‌سازی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 79,
        question: "چقدر به توانایی کار با زبان‌های برنامه‌نویسی مختلف اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 80,
        question: "چقدر به توانایی مدیریت و استفاده از پایگاه‌های داده رابطه‌ای اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 81,
        question: "چقدر به توانایی استفاده از زبان‌های نشانه‌گذاری (مانند HTML و XML) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 82,
        question: "چقدر به توانایی استفاده از زبان‌های سبک‌دهی (مانند CSS) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 83,
        question: "چقدر به توانایی استفاده از کتابخانه‌ها و فریم‌ورک‌های جاوااسکریپت اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 84,
        question: "چقدر به توانایی پیاده‌سازی و مدیریت APIها (رابط‌های برنامه‌نویسی) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 85,
        question: "چقدر به توانایی استفاده از سیستم‌های مدیریت محتوا (مانند WordPress) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 86,
        question: "چقدر به توانایی مدیریت و توسعه اپلیکیشن‌های موبایل اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 87,
        question: "چقدر به توانایی استفاده از سیستم‌های پایگاه داده‌های NoSQL (مانند MongoDB) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 88,
        question: "چقدر به توانایی استفاده از ابزارهای تست نرم‌افزار (مانند Selenium) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 89,
        question: "چقدر به توانایی مدیریت و تحلیل داده‌های بزرگ (Big Data) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 90,
        question: "چقدر به توانایی استفاده از تکنولوژی‌های هوش مصنوعی و یادگیری ماشین اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 91,
        question: "چقدر به توانایی استفاده از ابزارهای مدیریت پروژه‌های نرم‌افزاری (مانند Jira) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 92,
        question: "چقدر به توانایی مدیریت و استفاده از سرویس‌های ابری برای ذخیره‌سازی داده‌ها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 93,
        question: "چقدر به توانایی استفاده از تکنیک‌های بهینه‌سازی پایگاه داده‌ها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 94,
        question: "چقدر به توانایی استفاده از تکنولوژی‌های بلاک‌چین برای توسعه اپلیکیشن‌ها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    // {
    //     id: 94,
    //     question: "چقدر به توانایی خود در ایجاد رابط‌های کاربری کاربرپسند اعتماد دارید؟",
    //     options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
    //     answer: "بسیار زیاد"
    // },
    {
        id: 95,
        question: "چقدر به توانایی خود در استفاده از کتابخانه‌های جاوااسکریپت (مانند jQuery) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 96,
        question: "چقدر به توانایی خود در نوشتن کدهای مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 97,
        question: "چقدر به توانایی خود در مدیریت محیط‌های توسعه نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 98,
        question: "چقدر به توانایی خود در استفاده از مفاهیم برنامه‌نویسی شیء‌گرا (OOP) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 99,
        question: "چقدر به توانایی خود در ایجاد و مدیریت سرویس‌های وب (مانند RESTful) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 100,
        question: "چقدر به توانایی خود در استفاده از ابزارهای آنالیز داده‌ها (مانند Google Analytics) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 101,
        question: "چقدر به توانایی خود در حل مشکلات پیچیده نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 102,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت کد (مانند GitHub) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 103,
        question: "چقدر به توانایی خود در مدیریت پروژه‌های متن‌باز اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 104,
        question: "چقدر به توانایی خود در یادگیری تکنولوژی‌های جدید و پیشرفته اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 105,
        question: "چقدر به توانایی خود در ارتباط با مشتریان و کاربران نهایی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 106,
        question: "چقدر به توانایی خود در مدیریت منابع مالی پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 107,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی الگوریتم‌های پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 108,
        question: "چقدر به توانایی خود در ایجاد و مدیریت سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 109,
        question: "چقدر به توانایی خود در مدیریت و نگهداری سرورها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 110,
        question: "چقدر به توانایی خود در پیاده‌سازی نرم‌افزارهای ایمن و پایدار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 111,
        question: "چقدر به توانایی خود در مدیریت چالش‌ها و مشکلات تیمی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 112,
        question: "چقدر به توانایی خود در ایجاد تست‌های خودکار برای نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 113,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت کیفیت نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 114,
        question: "چقدر به توانایی خود در بهینه‌سازی عملکرد پایگاه‌های داده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 115,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های ابری برای پیاده‌سازی نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 116,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوهای طراحی نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 117,
        question: "چقدر به توانایی خود در مدیریت پیکربندی‌ها و تنظیمات نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 118,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل عملکرد نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 119,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت و مانیتورینگ سرورها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 120,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های متن‌باز برای پیاده‌سازی نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 121,
        question: "چقدر به توانایی خود در مدیریت و نگهداری نرم‌افزارهای بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 122,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل لاگ‌ها و خطاهای نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 123,
        question: "چقدر به توانایی خود در مدیریت و مانیتورینگ زیرساخت‌های ابری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 124,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 125,
        question: "چقدر به توانایی خود در مدیریت امنیت نرم‌افزارها و داده‌ها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 126,
        question: "چقدر به توانایی خود در استفاده از ابزارهای بهینه‌سازی عملکرد نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 127,
        question: "چقدر به توانایی خود در مدیریت پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 128,
        question: "چقدر به توانایی خود در ایجاد و مدیریت کلاسترهای داده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 129,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مجازی‌سازی و کانتینری‌سازی آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 130,
        question: "چقدر به توانایی خود در پیاده‌سازی اصول امنیت سایبری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 131,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل و مصورسازی داده‌ها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 132,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی الگوریتم‌های یادگیری ماشین اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 133,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت دیتابیس آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 134,
        question: "چقدر به توانایی خود در توسعه نرم‌افزارهای مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 135,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 136,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 137,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مانیتورینگ و اشکال‌زدایی سرورهای نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 138,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت پروژه‌های نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 139,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی معماری‌های نرم‌افزاری پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 140,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل کدهای نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 141,
        question: "چقدر به توانایی خود در مدیریت داده‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 142,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌های بزرگ آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 143,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌ها و رویدادهای نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 144,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوریتم‌های بهینه‌سازی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 145,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل امنیت نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 146,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های پایدار و مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 147,
        question: "چقدر به توانایی خود در مدیریت بحران‌ها و چالش‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 148,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت خطاهای نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 149,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مصورسازی داده‌ها (مانند Tableau) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 150,
        question: "چقدر به توانایی خود در مدیریت منابع پروژه‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 151,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی الگوریتم‌های پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 152,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های نوظهور برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 153,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 154,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوهای طراحی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 155,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌های نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 156,
        question: "چقدر به توانایی خود در بهینه‌سازی عملکرد نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 157,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مانیتورینگ و اشکال‌زدایی نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 158,
        question: "چقدر به توانایی خود در مدیریت منابع مالی پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 159,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 160,
        question: "چقدر به توانایی خود در مدیریت پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 161,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مجازی‌سازی و کانتینری‌سازی آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 162,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های متن‌باز برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 163,
        question: "چقدر به توانایی خود در مدیریت و نگهداری نرم‌افزارهای بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 164,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل کدهای نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 165,
        question: "چقدر به توانایی خود در مدیریت داده‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 166,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌های بزرگ آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 167,
        question: "چقدر به توانایی خود در مدیریت و مانیتورینگ زیرساخت‌های ابری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 168,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت پروژه‌های نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 169,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 170,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 171,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوریتم‌های بهینه‌سازی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 172,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌ها و رویدادهای نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 173,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌ها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 174,
        question: "چقدر به توانایی خود در مدیریت منابع پروژه‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 175,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی الگوریتم‌های پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 176,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های نوظهور برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 177,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 178,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوهای طراحی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 179,
        question: "چقدر به توانایی خود در مدیریت و بهینه‌سازی عملکرد نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 180,
        question: "چقدر به توانایی خود در مدیریت بحران‌ها و چالش‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 181,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت خطاهای نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 182,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مصورسازی داده‌ها (مانند Tableau) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 183,
        question: "چقدر به توانایی خود در مدیریت پروژه‌های متن‌باز اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 184,
        question: "چقدر به توانایی خود در استفاده از ابزارهای خودکارسازی (مانند CI/CD) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 185,
        question: "چقدر به توانایی خود در استفاده از فریم‌ورک‌های برنامه‌نویسی (مانند React یا Angular) اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 186,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت کیفیت نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 187,
        question: "چقدر به توانایی خود در استفاده از ابزارهای آنالیز داده‌ها (مانند Google Analytics) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 188,
        question: "چقدر به توانایی خود در مدیریت پیکربندی‌ها و تنظیمات نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 189,
        question: "چقدر به توانایی خود در استفاده از سیستم‌های مدیریت محتوای وب (مانند WordPress) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 190,
        question: "چقدر به توانایی خود در نوشتن کدهای قابل تست و نگهداری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 191,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی معماری‌های نرم‌افزاری پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 192,
        question: "چقدر به توانایی خود در مدیریت منابع مالی پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 193,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل عملکرد نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 194,
        question: "چقدر به توانایی خود در ایجاد تست‌های خودکار برای نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 195,
        question: "چقدر به توانایی خود در پیاده‌سازی اصول امنیت سایبری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 196,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل لاگ‌ها و خطاهای نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 197,
        question: "چقدر به توانایی خود در مدیریت تیم‌های برنامه‌نویسی اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 198,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های پایدار و مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 199,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوریتم‌های یادگیری ماشین اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 200,
        question: "چقدر به توانایی خود در مدیریت فرآیندهای توسعه نرم‌افزار اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 201,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 202,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت دیتابیس آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 203,
        question: "چقدر به توانایی خود در مدیریت و نگهداری نرم‌افزارهای بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 204,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل امنیت نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 205,
        question: "چقدر به توانایی خود در مدیریت زیرساخت‌های شبکه اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 206,
        question: "چقدر به توانایی خود در مدیریت بحران‌ها و چالش‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 207,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت خطاهای نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 208,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مصورسازی داده‌ها (مانند Tableau) آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 209,
        question: "چقدر به توانایی خود در مدیریت منابع پروژه‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 210,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی الگوریتم‌های پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 211,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های نوظهور برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 212,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 213,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوهای طراحی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 214,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌های نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 215,
        question: "چقدر به توانایی خود در بهینه‌سازی عملکرد نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 216,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مانیتورینگ و اشکال‌زدایی نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 217,
        question: "چقدر به توانایی خود در مدیریت منابع مالی پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 218,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 219,
        question: "چقدر به توانایی خود در مدیریت پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 220,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مجازی‌سازی و کانتینری‌سازی آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 221,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های متن‌باز برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 222,
        question: "چقدر به توانایی خود در مدیریت و نگهداری نرم‌افزارهای بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 223,
        question: "چقدر به توانایی خود در طراحی سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 224,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 225,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوریتم‌های بهینه‌سازی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 226,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌ها و رویدادهای نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 227,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌ها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 228,
        question: "چقدر به توانایی خود در مدیریت منابع پروژه‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 229,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی الگوریتم‌های پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 230,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های نوظهور برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 231,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 232,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوهای طراحی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 233,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌های نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 234,
        question: "چقدر به توانایی خود در بهینه‌سازی عملکرد نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 235,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مانیتورینگ و اشکال‌زدایی نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 236,
        question: "چقدر به توانایی خود در مدیریت منابع مالی پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 237,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 238,
        question: "چقدر به توانایی خود در مدیریت پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 239,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مجازی‌سازی و کانتینری‌سازی آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 240,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های متن‌باز برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 241,
        question: "چقدر به توانایی خود در مدیریت و نگهداری نرم‌افزارهای بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 242,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل کدهای نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 243,
        question: "چقدر به توانایی خود در مدیریت داده‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 244,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌های بزرگ آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 245,
        question: "چقدر به توانایی خود در مدیریت و مانیتورینگ زیرساخت‌های ابری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 246,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت پروژه‌های نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 247,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 248,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 249,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوریتم‌های بهینه‌سازی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 250,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌ها و رویدادهای نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 251,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌ها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 252,
        question: "چقدر به توانایی خود در مدیریت منابع پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 253,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی الگوریتم‌های پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 254,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های نوظهور برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 255,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 256,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوهای طراحی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 257,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌های نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 258,
        question: "چقدر به توانایی خود در بهینه‌سازی عملکرد نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 259,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مانیتورینگ و اشکال‌زدایی نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 260,
        question: "چقدر به توانایی خود در مدیریت منابع مالی پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 261,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 262,
        question: "چقدر به توانایی خود در مدیریت پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 263,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مجازی‌سازی و کانتینری‌سازی آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 264,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های متن‌باز برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 265,
        question: "چقدر به توانایی خود در مدیریت و نگهداری نرم‌افزارهای بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 266,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل کدهای نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 267,
        question: "چقدر به توانایی خود در مدیریت داده‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 268,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌های بزرگ آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 269,
        question: "چقدر به توانایی خود در مدیریت و مانیتورینگ زیرساخت‌های ابری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 270,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت پروژه‌های نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 271,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 272,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 273,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوریتم‌های بهینه‌سازی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 274,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌ها و رویدادهای نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 275,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌ها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 276,
        question: "چقدر به توانایی خود در مدیریت منابع پروژه‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 277,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی الگوریتم‌های پیچیده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 278,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های نوظهور برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 279,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 280,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوهای طراحی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 281,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌های نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 282,
        question: "چقدر به توانایی خود در بهینه‌سازی عملکرد نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 283,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مانیتورینگ و اشکال‌زدایی نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 284,
        question: "چقدر به توانایی خود در مدیریت منابع مالی پروژه‌های نرم‌افزاری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 285,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های مقیاس‌پذیر اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 286,
        question: "چقدر به توانایی خود در مدیریت پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 287,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مجازی‌سازی و کانتینری‌سازی آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 288,
        question: "چقدر به توانایی خود در استفاده از فناوری‌های متن‌باز برای توسعه نرم‌افزارها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 289,
        question: "چقدر به توانایی خود در مدیریت و نگهداری نرم‌افزارهای بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 290,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل کدهای نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 291,
        question: "چقدر به توانایی خود در مدیریت داده‌های بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 292,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌های بزرگ آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 293,
        question: "چقدر به توانایی خود در مدیریت و مانیتورینگ زیرساخت‌های ابری اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 294,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت پروژه‌های نرم‌افزاری آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 295,
        question: "چقدر به توانایی خود در طراحی و پیاده‌سازی سیستم‌های توزیع شده اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 296,
        question: "چقدر به توانایی خود در مدیریت ریسک‌های امنیتی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 297,
        question: "چقدر به توانایی خود در پیاده‌سازی الگوریتم‌های بهینه‌سازی نرم‌افزارها اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 298,
        question: "چقدر به توانایی خود در استفاده از ابزارهای مدیریت لاگ‌ها و رویدادهای نرم‌افزار آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 299,
        question: "چقدر به توانایی خود در استفاده از ابزارهای تحلیل داده‌ها آشنا هستید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    },
    {
        id: 300,
        question: "چقدر به توانایی خود در مدیریت منابع پروژه‌های نرم‌افزاری بزرگ اعتماد دارید؟",
        options: ["خیلی کم", "گاه‌گاهی", "به ندرت", "بسیار زیاد"],
        answer: "بسیار زیاد"
    }
];

// eslint-disable-next-line import/prefer-default-export
export {questions}