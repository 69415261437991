import React from 'react';
import Lottie from 'lottie-react';
import testIcon from '../../assert/Dev.json';

const NestedIcon = () => (
    // <div className="test-icon">
    <div className="nestedIcon-icon">
        <Lottie animationData={testIcon} loop className="animation"/>
    </div>
);
export default NestedIcon;
