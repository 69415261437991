import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Form, Input, message, notification, Progress, Radio, Space, Statistic, Typography} from "antd";
import {Helmet} from "react-helmet";
import {Howler} from 'howler';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FiCopy} from 'react-icons/fi';
import emailjs from 'emailjs-com';
import Footer from "../../Footer/Footer";
import {ThemeContext} from "../../../contexts/theme";
import HeaderPage from "../../Header/HeaderPage";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import "./Question.css"
import 'antd/dist/antd.css';
import Contact from "../../Contact/Contact";
import {questions} from '../../../questions'
import {about, aboutpersion} from "../../../portfolio";

const {Title} = Typography;

const shuffleArray = (array) => {
    // eslint-disable-next-line no-plusplus
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        // eslint-disable-next-line no-param-reassign
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const getRandomDiscountCode = () => {
    const discountCodes = ["DISCOUNT10", "SAVE20", "OFFER30", "PROMO40", "DEAL50"];
    const randomIndex = Math.floor(Math.random() * discountCodes.length);
    return discountCodes[randomIndex];
};

const Question = () => {
    const {name} = about;
    const {titled, namepersion, lastname, prefix, suffix} = aboutpersion;

    const [{themeName}] = useContext(ThemeContext)
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showScore, setShowScore] = useState(false);
    const [showIntro, setShowIntro] = useState(true);
    const [showNextLevel, setShowNextLevel] = useState(false);
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const [showMediumLevel, setShowMediumLevel] = useState(false);
    const [history, setHistory] = useState([]);
    const [shuffledOptions, setShuffledOptions] = useState([]);

    useEffect(() => {
        setShuffledOptions(shuffleArray([...questions[currentQuestion].options]));
    }, [currentQuestion]);

    const handleStartQuiz = () => {
        setShowIntro(false);
        setShowNextLevel(false);
        setShowScore(false);
        setShowRegistrationForm(false);
        setShowMediumLevel(false);
        setCurrentQuestion(0);
        setScore(0);
        setSelectedOption(null);
        setHistory([]);
        // playMusic();
    };

    const handleOptionChange = (e) => {
        const selected = e.target.value;
        setSelectedOption(selected);
        setTimeout(() => {
            if (selected === questions[currentQuestion].answer) {
                setScore(score + 1);
            }
            setHistory([...history, {question: currentQuestion, answer: selected}]);
            setSelectedOption(null);
            if (currentQuestion < questions.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
            } else {
                setShowScore(true);
                // eslint-disable-next-line no-use-before-define
                stopMusic();
            }
        }, 500);
    };

    const handleNextQuestion = () => {
        if (selectedOption === questions[currentQuestion].answer) {
            setScore(score + 1);
        }
        setHistory([...history, {question: currentQuestion, answer: selectedOption}]);
        setSelectedOption(null);
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowScore(true);
        }
    };

    const handleRestart = () => {
        setCurrentQuestion(0);
        setScore(0);
        setSelectedOption(null);
        setShowScore(false);
        setShowNextLevel(false);
        setShowRegistrationForm(false);
        setShowMediumLevel(false);
        setHistory([]);
        setShowIntro(true);
    };
    const handlePreviousQuestion = () => {
        if (history.length > 0) {
            const lastEntry = history.pop();
            setCurrentQuestion(lastEntry.question);
            setSelectedOption(lastEntry.answer);
            setHistory(history);
        }
    };

    const getScoreLevel = () => {
        const percentage = (score / questions.length) * 100;
        if (percentage === 100) return {
            level: "عالی",
            color: "green",
            suggestion: "شما عملکرد بسیار عالی داشتید! پیشنهاد می‌کنیم به مراحل پیشرفته‌تر برنامه‌نویسی بپردازید.",
            nextLevel: "مرحله پیشرفته",
            // details: `در این مرحله، شما می‌توانید به پروژه‌های پیچیده‌تر و چالش‌های برنامه‌نویسی پیشرفته بپردازید. کد تخفیف شما: ${getRandomDiscountCode()}`
            details: `در این مرحله، شما می‌توانید به پروژه‌های پیچیده‌تر و چالش‌های برنامه‌نویسی پیشرفته بپردازید.`
        };
        if (percentage >= 75) return {
            level: "خیلی خوب",
            color: "blue",
            suggestion: "شما عملکرد خیلی خوبی داشتید! پیشنهاد می‌کنیم به تمرینات بیشتر و پروژه‌های عملی بپردازید.",
            nextLevel: "مرحله متوسط",
            details: "در این مرحله، شما می‌توانید به تمرینات عملی و پروژه‌های متوسط بپردازید تا مهارت‌های خود را تقویت کنید."
        };
        if (percentage >= 50) return {
            level: "متوسط",
            color: "orange",
            suggestion: "شما عملکرد متوسطی داشتید. پیشنهاد می‌کنیم به مرور مفاهیم پایه و تمرینات بیشتر بپردازید.",
            nextLevel: "مرحله مقدماتی",
            details: "در این مرحله، شما می‌توانید به مرور مفاهیم پایه و تمرینات ساده‌تر بپردازید تا مهارت‌های خود را بهبود بخشید."
        };
        return {
            level: "ضعیف",
            color: "red",
            suggestion: "شما نیاز به تمرین بیشتری دارید. پیشنهاد می‌کنیم به مرور مفاهیم پایه و تمرینات ساده‌تر بپردازید.",
            nextLevel: "مرحله ابتدایی",
            details: "در این مرحله، شما می‌توانید به مرور مفاهیم پایه و تمرینات ساده‌تر بپردازید تا مهارت‌های خود را تقویت کنید."
        };
    };

    const {level, color, suggestion, nextLevel, details} = getScoreLevel();

    const handleNextLevel = () => {
        // if (nextLevel === "مرحله متوسط") {
        //     setShowMediumLevel(true);
        // } else if (nextLevel === "خیلی خوب") {
        //     setShowRegistrationForm(true);
        // } else {
        setShowScore(false);
        setShowNextLevel(true);
        // }
    };

    // const handleRegistrationFinish = () => {
    //     message.success("کارشناسان ما با" +
    //         "" +
    //         " شما تماس می‌گیرند.");
    //     setShowRegistrationForm(false);
    //     setShowMediumLevel(false);
    //     setShowIntro(true);
    // };
    // const handleRegistrationFinish = (values) => {
    //     emailjs.send('service_f8ennah', 'template_oshgzas', values, 'JGkhpcbD8FuQB2tCx').then((response) => {
    //         console.log('SUCCESS!', response.status, response.text);
    //     }, (err) => {
    //         console.log('FAILED...', err);
    //     });
    // };

    // const handleRegistrationFinish = (values) => {
    //
    //     console.log("::::::::::::::");
    //     console.log(values);
    //
    //     const templateParamsUser = {
    //         name: values.name,
    //         email: values.email,
    //         to_email: values.email,
    //     };
    //
    //     const templateParamsAdmin = {
    //         name: values.name,
    //         email: values.email,
    //         to_email: 'javataredu@gmail.com',
    //     };
    //
    //     emailjs.send('service_f8ennah', 'template_oshgzas', templateParamsUser, 'JGkhpcbD8FuQB2tCx')
    //         .then((response) => {
    //             console.log('SUCCESS! User Email:', response.status, response.text);
    //         }, (err) => {
    //             console.log('FAILED... User Email:', err);
    //         });
    //
    //     emailjs.send('service_f8ennah', 'template_dbg90od', templateParamsAdmin, 'JGkhpcbD8FuQB2tCx')
    //         .then((response) => {
    //             console.log('SUCCESS! Admin Email:', response.status, response.text);
    //         }, (err) => {
    //             console.log('FAILED... Admin Email:', err);
    //         });
    // };

    const handleRegistrationFinish = (values) => {
        console.log("::::::::::::::");
        console.log(values);
        const templateParamsUser = {
            name: values.name,
            ail: values.email,
            to_email: values.email,};
        const templateParamsAdmin = {
            name: values.name,
            email: values.email,
            to_email: 'javataredu@gmail.com',};
        emailjs.send('service_f8ennah', 'template_oshgzas', templateParamsUser, 'JGkhpcbD8FuQB2tCx').then((response) => {
            console.log('SUCCESS! User Email:', response.status, response.text);
            notification.success({
                message: `ثبت‌نام موفقیت‌آمیز`,
                description: `${values.name} عزیز، ثبت‌نام شما با موفقیت انجام شد و در آینده با شما تماس گرفته می‌شود.`,
            });
        }, (err) => {
            console.log('FAILED... User Email:', err);
            notification.error({
                message: 'خطا در ثبت‌نام',
                description: 'ارسال ایمیل کاربر با خطا مواجه شد. لطفاً دوباره تلاش کنید.',
            });
        });
        emailjs.send('service_f8ennah', 'template_dbg90od', templateParamsAdmin, 'JGkhpcbD8FuQB2tCx').then((response) => {
            console.log('SUCCESS! Admin Email:', response.status, response.text);
        }, (err) => {
            console.log('FAILED... Admin Email:', err);
        });
    };

    const stopMusic = () => {
        Howler.stop();
    };

    const [text] = useState(getRandomDiscountCode());
    const [copied, setCopied] = useState(false);

    return (
        <div id='top' className={`${themeName} app`}>
            <HeaderPage/>
            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>
                <div>
                    <div/>
                    <div>
                        <br/>
                        <Helmet>
                            <title>کوییز برنامه‌نویسی</title>
                        </Helmet>
                        <Title level={2} className="sogand" style={{direction: 'rtl', textAlign: 'center'}}>کوییز
                            برنامه‌نویسی</Title>
                        <br/>
                    </div>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {showIntro ? (
                        <Card>
                            <div style={{direction: 'rtl', textAlign: 'center'}}>
                                <Title level={3} className="bNazanintdr">نکات قبل
                                    از شروع کوییز</Title>
                                <p className="bNazanintdr">لطفاً قبل از شروع کوییز به نکات زیر
                                    توجه کنید:</p>
                                <ul className="bNazanintdr" style={{textAlign: 'right', listStyleType: 'disc'}}>
                                    <li>هر سوال چهار گزینه دارد و تنها یک گزینه صحیح است.</li>
                                    <li>پس از اتمام کوییز، نتیجه و سطح شما نمایش داده خواهد شد.</li>
                                    <li>گزارش عملکرد خود را برای ما ارسال کنید تا بتوانیم راهنمایی‌های لازم را ارائه دهیم.</li>
                                </ul>
                                <Button type="primary" onClick={handleStartQuiz} className="bNazanintdr">شروع
                                    کوییز</Button>
                            </div>
                        </Card>
                        // eslint-disable-next-line no-nested-ternary
                    ) : showScore ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}>
                            <Card title="نتایج کویز" style={{width: 300}} className="vazir">
                                <div className="bNazanintdr">
                                    <Statistic title="تعداد سوالات" value={questions.length}
                                               valueStyle={{fontSize: '20px', fontFamily: 'bNazanin'}}/>
                                    <Statistic title="تعداد جواب‌های درست" value={score}
                                               valueStyle={{fontSize: '20px', fontFamily: 'bNazanin'}}/>
                                    <Statistic title="امتیاز کلی" value={score.toFixed(2)} suffix="%"
                                               valueStyle={{fontSize: '20px', fontFamily: 'bNazanin'}}/>
                                    <Statistic title="سطح" value={level}
                                               valueStyle={{color, fontSize: '20px', fontFamily: 'bNazanin'}}/>
                                    {/* <Statistic title="پیشنهاد" value={suggestion} */}
                                    {/*            valueStyle={{fontSize: '20px', fontFamily: 'bNazanin'}}/> */}
                                </div>
                                <br/>
                                <Progress percent={score} status="active"/>
                                <br/>
                                <br/>
                                <Space>
                                    <Button type="primary" onClick={handleRestart}
                                            className="bNazanintdr">شروع مجدد</Button>
                                    <Button type="primary" onClick={handleNextLevel} className="bNazanintdr">انتقال
                                        به {nextLevel}</Button>
                                </Space>
                            </Card>
                        </div>
                        // eslint-disable-next-line no-nested-ternary
                    ) : showNextLevel ? (
                        <Card>
                            <div style={{direction: 'rtl', textAlign: 'center'}}>
                                <Title level={3} className="bNazanintdr">جزئیات {nextLevel}</Title>
                                <p className="bNazanintdr">{details}</p>
                                <Space>
                                    {nextLevel === "مرحله ابتدایی" && (
                                        <Space>
                                            {/* <Button type="primary" href="https://typeo.top/" target="_blank" */}
                                            {/*         className="bNazanintdr">تایپ 10 */}
                                            {/*     انگشتی</Button> */}
                                            <Button type="primary" onClick={handleRestart}
                                                    className="bNazanintdr">شروع مجدد</Button>
                                        </Space>
                                    )}
                                    {nextLevel === "مرحله مقدماتی" && (
                                        <Space>
                                            <Button type="primary" href="https://typeo.top/" target="_blank"
                                                    className="bNazanintdr">تایپ 10
                                                انگشتی</Button>
                                            <Button type="primary" onClick={handleRestart}
                                                    className="bNazanintdr">شروع مجدد</Button>
                                        </Space>
                                    )}
                                    {nextLevel === "مرحله پیشرفته" && (
                                        <div className="bNazanintdr">
                                            <p>لطفاً کد تخفیف را نزد خود نگهداری کنید و با تیم
                                                پشتیبانی تماس بگیرید</p>
                                            <div>
                                                <p> کد تخفیف شما: {text} <CopyToClipboard
                                                    text={text} onCopy={() => setCopied(true)}>
                                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
                                                    <button style={{marginLeft: '10px'}}><FiCopy/></button>
                                                </CopyToClipboard></p>
                                                {copied ? <span style={{color: 'green'}}>کپی شد!</span> : null}
                                            </div>
                                            <Space>
                                                <div>
                                                    <Card>
                                                        <Title
                                                            level={3}
                                                            className="bNazanintdr">فرم ثبت نام
                                                        </Title>
                                                        <Form onFinish={handleRegistrationFinish}
                                                              layout="vertical">
                                                            <Form.Item
                                                                name="name"
                                                                label="نام"
                                                                rules={[{
                                                                    required: true,
                                                                    message: 'لطفاً نام خود را وارد کنید!'
                                                                }]}>
                                                                <Input className="bNazanintdr"/>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="email"
                                                                label="ایمیل"
                                                                rules={[{
                                                                    required: true,
                                                                    message: 'لطفاً ایمیل خود را وارد کنید!',
                                                                    type: 'email'
                                                                }]}>
                                                                <Input className="bNazanintdr"/>
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Button
                                                                    type="primary" htmlType="submit"
                                                                    className="bNazanintdr"> ثبت نام
                                                                </Button>
                                                            </Form.Item>
                                                        </Form>
                                                        {/* </div> */}
                                                    </Card>
                                                    <br/>
                                                    <Button type="primary" onClick={handleRestart}
                                                            className="bNazanintdr">شروع
                                                        مجدد</Button>
                                                </div>
                                            </Space>
                                        </div>
                                    )}
                                    {nextLevel === "مرحله متوسط" && (
                                        <Space>
                                            <div>
                                                <Card>
                                                    {/* <div style={{direction: 'rtl', textAlign: 'center'}}> */}
                                                    <Title
                                                        level={3}
                                                        className="bNazanintdr">فرم ثبت نام
                                                    </Title>
                                                    <Form onFinish={handleRegistrationFinish}
                                                          layout="vertical">
                                                        <Form.Item
                                                            name="name"
                                                            label="نام"
                                                            rules={[{
                                                                required: true,
                                                                message: 'لطفاً نام خود را وارد کنید!'
                                                            }]}>
                                                            <Input className="bNazanintdr"/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="email"
                                                            label="ایمیل"
                                                            rules={[{
                                                                required: true,
                                                                message: 'لطفاً ایمیل خود را وارد کنید!',
                                                                type: 'email'
                                                            }]}>
                                                            <Input className="bNazanintdr"/>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button
                                                                type="primary" htmlType="submit"
                                                                className="bNazanintdr"> ثبت نام
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                    {/* </div> */}
                                                </Card>
                                                <br/>
                                                <Button type="primary" onClick={handleRestart}
                                                        className="bNazanintdr">شروع
                                                    مجدد</Button>
                                            </div>
                                        </Space>
                                    )}
                                </Space>
                            </div>
                        </Card>
                        // eslint-disable-next-line no-nested-ternary
                    ) : showMediumLevel ? (<Card>
                            <div style={{direction: 'rtl', textAlign: 'center'}}><Title level={3}
                                                                                        className="bNazanintdr">جزئیات
                                مرحله متوسط</Title> <p className="bNazanintdr">{details}</p> <Form
                                onFinish={handleRegistrationFinish} layout="vertical"> <Form.Item name="name"
                                                                                                  label="نام"
                                                                                                  rules={[{
                                                                                                      required: true,
                                                                                                      message: 'لطفاً نام خود را وارد کنید!'
                                                                                                  }]}> <Input
                                className="bNazanintdr"/> </Form.Item> <Form.Item name="email" label="ایمیل"
                                                                                  rules={[{
                                                                                      required: true,
                                                                                      message: 'لطفاً ایمیل خود را وارد کنید!',
                                                                                      type: 'email'
                                                                                  }]}> <Input
                                className="bNazanintdr"/> </Form.Item> <Form.Item> <Button type="primary"
                                                                                           htmlType="submit"
                                                                                           className="bNazanintdr">ثبت
                                نام</Button> </Form.Item> </Form></div>
                        </Card>
                    ) : showRegistrationForm ? (
                        <Card>
                            <div style={{direction: 'rtl', textAlign: 'center'}}>
                                <Title level={3} className="bNazanintdr">فرم ثبت نام</Title>
                                <Form onFinish={handleRegistrationFinish} layout="vertical">
                                    <Form.Item
                                        name="name" label="نام"
                                        rules={[{required: true, message: 'لطفاً نام خود را وارد کنید!'}]}>
                                        <Input className="bNazanintdr"/>
                                    </Form.Item>
                                    <Form.Item name="email" label="ایمیل"
                                               rules={[{
                                                   required: true,
                                                   message: 'لطفاً ایمیل خود را وارد کنید!',
                                                   type: 'email'
                                               }]}>
                                        <Input className="bNazanintdr"/>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="bNazanintdr"> ثبت
                                            نام </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Card>
                    ) : (
                        <>
                            <div style={{direction: 'rtl', textAlign: 'right'}}><Title level={3}
                                                                                       className="bNazanint">سوال {questions[currentQuestion].id} از {questions.length}</Title>
                                <Title level={4}
                                       className="bNazanintdrb">{questions[currentQuestion].question}</Title>
                                <Radio.Group onChange={handleOptionChange} value={selectedOption}>
                                    {/* eslint-disable-next-line no-shadow */}
                                    <Space direction="vertical"> {shuffledOptions.map((option) => (
                                        <Radio className="bNazanintdr" key={option}
                                               value={option}>{option}</Radio>))}
                                    </Space>
                                </Radio.Group>
                                <div>
                                    <br/>
                                    <Space>
                                        <Button onClick={handlePreviousQuestion}
                                                disabled={history.length === 0}
                                                className="bNazanintdr"> بازگشت به گزینه قبلی
                                        </Button>
                                    </Space>
                                </div>
                            </div>
                        </>
                    )
                    }
                </div>

                <br/>
                <br/>
                <br/>
                <br/>

                <Contact/>
            </main>

            <ScrollToTop/>
            <Footer/>
        </div>
    )

}

export default Question;