import {
    HomeOutlined, MobileOutlined, ShopOutlined, TrophyOutlined, UserAddOutlined, WhatsAppOutlined
} from '@ant-design/icons';
import {header} from '../../portfolio'
import './Header.css'
import NavbarPage from "../Navbar/NavbarPage";

const Header = () => {
    const {homepage, title} = header

    return (
        <header className='header center'>
            <h4>
                {homepage ? (
                    <a href={homepage} className='link'>
                        <HomeOutlined/>
                    </a>
                ) : (
                    title
                )}
                <span className='space'/>
                <a href="tel:+989122273119" className='link'>
                    <MobileOutlined/>
                </a>
                <span className='space'/>
                <a href="/signup" className='link' title="ثبت‌نام">
                    <UserAddOutlined/>
                </a>
                <span className='space'/>
                <a href="/shop" className='link' title="فروشگاه">
                    <ShopOutlined style={{color: '#ff9900'}}/>
                    {/* <ShopOutlined style={{color: 'rgba(101,2,108,0.47)'}}/> */}
                    {/* <ShopOutlined style={{color: 'rgba(255,0,38,0.38)'}}/> */}
                </a>
                <span className='space'/>
                <a href="https://wa.me/989122273119" className='link' target="_blank" rel="noopener noreferrer">
                    <WhatsAppOutlined style={{color: '#25D366'}}/>
                </a>
                <span className='space'/>
                <a href="/question" className='link' title="استعدادیابی">
                    {/* <TrophyOutlined style={{color: '#FFD700'}}/> */}
                    <TrophyOutlined className='animated-icon' style={{color: '#FFD700'}}/>
                    {/* <StarOutlined style={{color: '#FFD700'}}/> */}
                    {/* <SolutionOutlined style={{color: '#FFD700'}}/> */}
                </a>
            </h4>
            <NavbarPage/>
        </header>
    )
}

export default Header
